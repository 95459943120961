import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Hidden from '@material-ui/core/Hidden';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Poppers from '@material-ui/core/Popper';
import withStyles from '@material-ui/core/styles/withStyles';
import Notifications from '@material-ui/icons/Notifications';
import Person from '@material-ui/icons/Person';
import { logout } from 'actions/auth';
import { onSettings } from 'actions/dashboard';
import headerLinksStyle from 'assets/jss/material-dashboard-react/components/headerLinksStyle';
import classNames from 'classnames';
import Button from 'components/CustomButtons/Button.jsx';
import moment from 'moment';
import 'moment/locale/es';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { withTranslation } from 'react-i18next';
import Moment from 'react-moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
class HeaderLinks extends React.Component {
  state = {
    open: false,
    user: false,
    notificationsSeen: false
  };
  handleNotificationToggle = () => {
    this.setState(state => ({ open: !state.open, user: false }));
  };
  handleUserToggle = () => {
    this.setState(state => ({ open: false, user: !state.user }));
  };

  handleClose = event => {
    if (
      this.anchorEl.contains(event.target) ||
      this.anchorElogout.contains(event.target)
    ) {
      return;
    }
    localStorage.setItem('notificationsSeen', this.props.notifications.length);
    this.setState({ open: false, user: false });
  };

  handleSettings(event) {
    this.props.onSettings({ open: true, type: event });
    this.setState(state => ({ user: !state.user }));
  }

  handleLogout = () => {
    this.props.logout();
    this.setState(state => ({ user: !state.user }));
  };

  nickname = carid => {
    let item = this.props.cars.find(car => car.id === carid);
    if (item) return item.nickname;
    return '';
  };

  render() {
    if (localStorage.getItem('language') === 'Español') {
      moment.locale('es');
    } else {
      moment.locale('en');
    }
    const { classes, notifications, t } = this.props;
    const { open, user } = this.state;
    const limitNotifications = isNaN(
      parseInt(localStorage.getItem('notificationsSeen'))
    )
      ? 0
      : parseInt(localStorage.getItem('notificationsSeen'));
    return (
      <div>
        <div className={classes.manager}>
          <Button
            buttonRef={node => {
              this.anchorEl = node;
            }}
            color={window.innerWidth > 959 ? 'transparent' : 'primary'}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-owns={open ? 'menu-list-grow' : null}
            aria-haspopup='true'
            onClick={this.handleNotificationToggle}
            className={classes.buttonLink}
          >
            <Notifications
              className={classes.icons}
              style={{ color: '#29B7DA' }}
            />
            {notifications.length > limitNotifications && (
              <span className={classes.notifications}>
                {notifications.length - limitNotifications}
              </span>
            )}

            <Hidden mdUp implementation='css'>
              <p onClick={this.handleClick} className={classes.linkText}>
                {t('Notification')}
              </p>
            </Hidden>
          </Button>
          <Poppers
            open={open}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !open }) +
              ' ' +
              classes.pooperNav
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id='menu-list-grow'
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom'
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList
                      role='menu'
                      style={{
                        height:
                          notifications.length >= 5
                            ? '50vh'
                            : notifications.length * 10 + 'vh'
                      }}
                    >
                      <PerfectScrollbar>
                        {notifications.map(
                          (notifiy, key) =>
                            key + 1 > limitNotifications && (
                              <MenuItem
                                key={notifiy.id}
                                onClick={this.handleClose}
                                className={classes.dropdownItem}
                              >
                                <b>{this.nickname(notifiy.carid)}</b>{' '}
                                {notifiy.title}
                                <p>
                                  {' '}
                                  {notifiy.time && (
                                    <Moment format=' ddd, MMM DD YYYY, hh:mm A'>
                                      {notifiy.time}
                                    </Moment>
                                  )}
                                </p>
                              </MenuItem>
                            )
                        )}
                      </PerfectScrollbar>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
        </div>
        <Button
          buttonRef={node => {
            this.anchorElogout = node;
          }}
          color={window.innerWidth > 959 ? 'transparent' : 'primary'}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={user ? 'menu-list-grow' : null}
          aria-haspopup='true'
          aria-label='Person'
          className={classes.buttonLink}
          onClick={this.handleUserToggle}
        >
          <Person className={classes.icons} style={{ color: '#29B7DA' }} />
          <Hidden mdUp implementation='css'>
            <p className={classes.linkText}>Profile</p>
          </Hidden>
        </Button>
        <Poppers
          open={user}
          anchorEl={this.anchorElogout}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !user }) +
            ' ' +
            classes.pooperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id='menu-list-grow'
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList role='menu'>
                    <MenuItem
                      onClick={() => {
                        this.props.history.push('/profile');
                        this.setState({ open: false, user: false });
                      }}
                      className={classes.dropdownItem}
                    >
                      {t('Profile')}
                    </MenuItem>
                    <MenuItem
                      onClick={() => this.handleSettings('Language')}
                      className={classes.dropdownItem}
                    >
                      {t('Language')}
                    </MenuItem>
                    <MenuItem
                      onClick={() => this.handleSettings('ChangePassword')}
                      className={classes.dropdownItem}
                    >
                      {t('ChangePassword')}
                    </MenuItem>
                    <MenuItem
                      onClick={() => this.handleSettings('Units')}
                      className={classes.dropdownItem}
                    >
                      {t('Units')}
                    </MenuItem>
                    <MenuItem
                      onClick={this.handleLogout}
                      className={classes.dropdownItem}
                    >
                      {t('Logout')}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    cars: R.pathOr([], ['dashboard', 'markers'])(state),
    notifications: R.pathOr([], ['dashboard', 'notifications'])(state)
  };
}

const mapDispatchToProps = {
  logout: logout,
  onSettings: onSettings
};

HeaderLinks.propTypes = {
  logout: PropTypes.func,
  onSettings: PropTypes.func,
  t: PropTypes.func,
  notifications: PropTypes.array,
  cars: PropTypes.array,
  classes: PropTypes.object,
  history: PropTypes.object
};

export default compose(
  withStyles(headerLinksStyle),
  withRouter,
  withTranslation('translations'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(HeaderLinks);
