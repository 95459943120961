import withStyles from '@material-ui/core/styles/withStyles';
import logo from 'assets/img/img_logo1.png';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import PropTypes from 'prop-types';
import React from 'react';

const style = {
  grid: {
    padding: '0 15px !important',
    justifyContent: 'space-between'
  },
  logo: {
    float: 'right'
  }
};

function Policy({ ...props }) {
  return (
    <GridContainer
      style={{ justifyContent: 'space-around', margin: '0 0 !important' }}
    >
      <GridItem>
        <h2>
          <strong>Privacy Policy</strong>
          <img
            className={props.classes.logo}
            src={logo}
            alt='logo'
            width='450'
            height='134'
          />
        </h2>
        <p>&nbsp;</p>
        <p>
          <strong>Airmotive, LLC (Airmotive)</strong>
        </p>
        <p>
          <strong>Last updated April 21, 2019</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          This Privacy Policy establishes the terms on which AirMotive LLC
          (&quot;we&quot; or &quot;company&quot;) uses and protects the
          information that is provided by its users when using the
          company&apos;s website, the software platform and all the associated
          smart phone applications (collectively, &quot;the platform&quot;).
          This company is committed to the security of its users&apos; data.
          When we ask you to fill in the fields of personal information with
          which you can be identified, we do so by ensuring that it will only be
          used in accordance with the terms of this document. However, this
          Privacy Policy may change over time or be updated for what we
          recommend, and we strongly encourage you to continually review this
          page to ensure that you agree to such changes.
          <span className='Apple-converted-space'>&nbsp;</span>
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Information that is collected</strong>
        </p>
        <p>
          Our website may collect personal information, for example: Name,
          contact information such as your email address and demographic
          information. Also, when necessary, specific information may be
          required to process an order or make a delivery or billing.
        </p>
        <p>
          In order to access certain services and products that we offer through
          the Platform, we ask you to provide us with certain information that
          identifies you personally (&quot;Personal Information&quot;). Personal
          information may include the following categories of information: (1)
          contact information (such as your name, postal and e-mail address, or
          other optional information that you can provide through an
          application); (2) Financial data (such as your account or credit card
          number); and (3) Demographic data (such as your zip code, age, and
          income). If you communicate with us by email, post messages in any of
          our chat groups, electronic newsletters or forums, or complete online
          forms, surveys or contest entries, any information provided in such
          communication may be collected as personal information. This will
          include the email addresses of those who communicate with us by email,
          aggregate information about which pages the consumers access or visit,
          and voluntary information (for example, survey information and / or
          site records).
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Uses of the information collected</strong>
        </p>
        <p>
          We use contact information to send you information about our company
          or our products or services, or promotional material from some of our
          partners, or to contact you when necessary. We use your financial data
          to verify your eligibility for certain products or services and to
          send you an invoice for products and services. We use your demographic
          information to personalize and adapt your experience on the platform
          or application, displaying content that we believe may interest you
          and according to your preferences. We and the applications available
          on the Platform may use Personal Information to communicate with you
          regarding information that may interest you or to offer you coupons,
          services, advice, information or other exchangeable promotions. If you
          wish to unsubscribe from such communications, follow the unsubscribe
          instructions provided through the given application or the one
          provided in such communication.
        </p>
        <p>
          Our website uses the information in order to provide the best possible
          service, particularly to maintain a registry of users, orders if
          applicable, and improve our products and services. It is possible that
          periodic emails will be sent through our site with special offers, new
          products and other advertising information that we consider relevant
          to you or that may benefit you, these emails will be sent to the
          address you provide and may be canceled. anytime.
        </p>
        <p>
          AirMotive LLC is highly committed to fulfilling the commitment to keep
          your information secure. We use the most advanced systems and update
          them constantly to ensure that there is no unauthorized access.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Telematics</strong>
        </p>
        <p>
          The data from our connected AirMotive car service is used to provide
          you with vehicle monitoring service, to enable the functions of the
          movement application associated with AirMotive and to enable Wi-Fi
          connectivity in your car. In addition, your data may be shared with
          our application provider to enable third-party services that use your
          personal data, although in such cases no third party will be granted
          access to the data that identifies you without first obtaining your
          consent. We may also use such data for any of the other purposes
          listed in this policy, such as internal analysis, or to customize the
          offers we provide.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Advertising</strong>
        </p>
        <p>
          You can view advertisements when you visit our websites, mobile
          websites, mobile applications or on your device. We can help
          advertisers better reach our customers by providing them with certain
          information, including the type of device, geographic information,
          language preferences or demographic information obtained from other
          companies so that advertisers can determine which ads may be most
          relevant to them. you. However, we do not share personal information
          for advertising purposes outside of our corporate family without your
          consent.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Cookies</strong>
        </p>
        <p>
          A cookie refers to a file that is sent for the purpose of requesting
          permission to be stored on your computer, when accepting said file is
          created and the cookie then serves to have information regarding the
          traffic of a web portal, and also facilitates future visits to a
          recurring web portal. Another function that cookies have is that with
          them web portals can recognize you individually and therefore provide
          you with the best personalized service of your web portal.
        </p>
        <p>
          Our website uses cookies to identify the pages that are visited and
          their frequency. This information is used only for statistical
          analysis and then the information is permanently deleted. You can
          delete cookies at any time from your computer. However, cookies help
          to provide a better service of the websites, you are not giving access
          to your computer information or you, unless you want it and provide it
          directly. You can accept or deny the use of cookies; however, most
          browsers automatically accept cookies because it serves to have a
          better internet service. You can also change the configuration of your
          computer to decline cookies. If they decline, you may not be able to
          use some of our services.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Links to Third Parties</strong>
        </p>
        <p>
          This website may contain links to other sites that may be of interest
          to you. Once you click on these links and leave our page, we no longer
          have control over the site to which you are redirected and therefore
          we are not responsible for the terms or privacy or the protection of
          your data in those other third-party sites. These sites are subject to
          their own privacy policies, so it is advisable to consult them to
          confirm that you agree with them.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Control of your personal information</strong>
        </p>
        <p>
          At any time, you may restrict the collection or use of personal
          information that is provided to our website. Each time you are asked
          to fill in a form, such as the user registration form, you can check
          or uncheck the option to receive information by email. In case you
          have marked the option to receive our newsletter or advertising you
          can cancel it at any time.
        </p>
        <p>
          This company will not sell, assign or distribute personal information
          that is collected without your consent, unless required by a judge
          with a court order.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Changes</strong>
        </p>
        <p>
          We reserve the right, at any time and without notice, to add, change,
          update or modify this Privacy Policy, simply by posting such change,
          update or modification on the company&apos;s website and without any
          other notice to you. Any change, update or modification of this type
          will be effective immediately after its publication on the
          company&apos;s website.
        </p>
      </GridItem>
    </GridContainer>
  );
}

Policy.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(style)(Policy);
