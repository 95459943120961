import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  infoBatteryCardHeader,
  primaryCardHeader,
  carsCardHeader,
  yellowCardHeader
} from 'assets/jss/material-dashboard-react.jsx';
const cardIconStyle = {
  cardIcon: {
    '&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$infoBatteryCardHeader,&$primaryCardHeader,&$carsCardHeader,&$yellowCardHeader': {
      borderRadius: '3px',
      backgroundColor: '#999',
      padding: '15px',
      marginTop: '-20px',
      marginRight: '15px',
      float: 'left'
    }
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  infoBatteryCardHeader,
  primaryCardHeader,
  carsCardHeader,
  yellowCardHeader
};

export default cardIconStyle;
