/**
 * React Geocode Module
 *
 * @package react-geocode
 * @author  Pir Shukarulalh Shah <shuker_rashdi@hotmail.com>  (http://www.shukarullah.com)
 */
let API_KEY = 'AIzaSyD0N4e75HLlrE05-d9me1ejseeq1wOaoy4';
const GOOGLE_API = 'https://maps.google.com/maps/api/geocode/json';

async function handleUrl(url) {
  const response = await fetch(url).catch(error =>
    Promise.reject(new Error('Error fetching data'))
  );

  const json = await response.json().catch(() => {
    return Promise.reject(new Error('Error parsing server response'));
  });

  if (json.status === 'OK') {
    return json;
  }
  return Promise.reject(
    new Error(`Server returned status code ${json.status}`)
  );
}

export default {
  /**
   * @param {string} lat
   * @param {string} lng
   * @param {string} [apiKey]
   * @returns {Promise}
   */
  async fromLatLng(lat, lng, apiKey) {
    if (!lat || !lng) {
      return Promise.reject(new Error('Provided coordinates are invalid'));
    }

    const latLng = `${lat},${lng}`;
    let url = `${GOOGLE_API}?latlng=${encodeURI(latLng)}`;

    if (apiKey || API_KEY) {
      API_KEY = apiKey || API_KEY;
      url += `&key=${API_KEY}`;
    }

    return handleUrl(url);
  },

  /**
   * @param {string} address
   * @param {string} [apiKey]
   * @returns {Promise}
   */
  async fromAddress(address, apiKey) {
    if (!address) {
      return Promise.reject(new Error('Provided address is invalid'));
    }

    let url = `${GOOGLE_API}?address=${encodeURI(address)}`;

    if (apiKey || API_KEY) {
      API_KEY = apiKey || API_KEY;
      url += `&key=${API_KEY}`;
    }

    return handleUrl(url);
  }
};
