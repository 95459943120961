import withStyles from '@material-ui/core/styles/withStyles';
import logo from 'assets/img/img_logo1.png';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import PropTypes from 'prop-types';
import React from 'react';

const style = {
  grid: {
    padding: '0 15px !important',
    justifyContent: 'space-between'
  },
  logo: {
    float: 'right'
  }
};

function Terms({ ...props }) {
  return (
    <GridContainer
      style={{ justifyContent: 'space-around', margin: '0 0 !important' }}
    >
      <GridItem>
        <h2>
          <strong>Terms of service</strong>
          <img
            className={props.classes.logo}
            src={logo}
            alt='logo'
            width='450'
            height='134'
          />
        </h2>
        <p>&nbsp;</p>
        <p>
          <strong>Airmotive, LLC (Airmotive)</strong>
        </p>
        <p>
          <strong>Last revision: April 27, 2019</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          Airmotive LLC, (&quot;Airmotive,&quot; “myairmotive,” “Smarv,”
          &quot;we,&apos;&apos; &quot;our&quot; or &quot;Company&quot;) welcomes
          you (the &quot;User (s)&quot; or &quot;you&quot;) to use our Mobile
          Application, and our website Airmotive.app (the
          &quot;Application&quot;, the &quot;Web Application&quot;, the
          &quot;Site&quot; and collectively - the &quot;Services&quot;). Users
          may use the Service in accordance with the terms and conditions
          hereunder.
        </p>
        <br />
        <p>
          <strong>HOW DO I ACCEPT THESE T&Cs?</strong>
        </p>
        <br />
        <p>You accept these T&Cs by completing any of the following items:</p>
        <ul>
          <li>purchasing the Service with any rate plan;</li>
          <li>upon creating an account;</li>
          <li>
            giving us a written or electronic signature or conﬁrmation, or
            telling us orally that you accept;
          </li>
          <li>using or paying for the Service or a Device; or</li>
          <li>opening the Device box.</li>
        </ul>
        <p>
          If you do not wish to accept these T&Cs, do not take any of the above
          actions.
        </p>
        <p>
          When you accept, you&apos;re conﬁrming that you are of legal age
          (which means you are either legally emancipated, or have reached the
          age of majority as deﬁned in your jurisdiction) and that you are able
          to enter into a contract. If you accept for an organization,
          you&apos;re conﬁrming that you are authorized to bind that
          organization, and references to &quot;you&quot; in these T&Cs may mean
          the organization.
        </p>
        <ol>
          <li>
            <strong>The Service</strong>
          </li>
        </ol>
        <p>
          Airmotive oﬀers services that allow the diagnosis of the vehicle and
          pricing provisions of third-party service providers in the area of
          vehicle maintenance and used in accordance with the vehicle&apos;s
          diagnosis. The diagnosis is made by means of an OBDII dongle
          (&quot;Dongle&quot;) that is connected to the vehicle&apos;s computer
          port and transmits data through cellular connectivity, Bluetooth,
          Wi-Fi or through alternative routes that allow to connect to the
          vehicle&apos;s computer through third-party platforms, which run on a
          connected mobile device. The diagnostic will start automatically once
          the engine is turned on and the application is running (on the
          user&apos;s smartphone screen, or in the background).
        </p>
        <p>
          The dongle can be purchased through the site as described in the terms
          below. In certain cases, you may receive and/or may be oﬀered to buy
          the dongle from a third party that is a business partner of the
          company.
        </p>
        <p>
          In addition, on the site you can ﬁnd complete information about the
          company&apos;s systems and technology and other related content
          (including contact information, videos, text, logos, icons, images,
          data compilations, links, other specialized content, documentation,
          data, related graphics, intellectual property and more (collectively,
          the &quot;Content&quot;). In addition, users can register through the
          site to our email list to receive updates on company developments,
          etc. Please note that &quot;Airmotive&quot; is only a platform for
          price evaluations, diagnostic services and third-party proposal
          provisions.&quot; Airmotive is not and will not be a party to any deal
          or transaction between you and a third party. Airmotive is not the
          manufacturer of the dongle, it is not being tested by the company nor
          is it covered by the company&apos;s guarantee.
        </p>
        <ol start='2'>
          <li>
            <strong>Registration and user account</strong>
          </li>
        </ol>
        <p>
          Some of the features of the services are only available to registered
          users who have created a personal account (&quot;Account&quot;). For
          more information about the types of personal information collected by
          the company during the registration process, read our Privacy Policy
          available at the following address: Airmotive.app/privacy_policy.html.
          When you open an account, you will be asked to provide accurate and
          complete information to create the account, including a valid email
          address. You are completely and exclusively responsible for not
          disclosing your password, username, and account. If you wish to change
          your password, recover your password, change the username in your
          account and/or delete your account, you can contact us at:
          support@myairmotive.com. Modiﬁcation or deletion of your account will
          be made within a reasonable time after your request, but may take up
          to 72 hours, and as of that elimination you will no longer be able to
          access your account.
        </p>
        <ol start='3'>
          <li>
            <strong>Acquisition of the dongle</strong>
          </li>
        </ol>
        <p>
          The site allows, among other things, the acquisition of the dongle. To
          place an order for the dongle, you must ﬁrst choose the “purchase”
          option on the site, which will be followed by a &quot;Product
          page&quot; that shows information about the dongle, including, among
          others, the sale price (the &quot;Product page&quot;). Note that
          Airmotive can update the product page from time to time, add or remove
          information and update or change the price of the dongle. It is
          clariﬁed that the images on the site are only illustrative. The prices
          shown on the site do not include VAT according to the applicable law,
          unless otherwise indicated. To buy the dongle on the site, the user
          will be asked to request the purchase of the dongle (the
          &quot;Buyer&quot;) to enter certain information such as his/her name,
          address, email address and payment method (credit card).
        </p>
        <p>
          Be sure to provide accurate and correct information, or completion of
          the purchase can not be guaranteed. Providing false information is a
          crime and whoever does so may be subject to criminal and/or civil
          legal procedures. &quot;Airmotive&quot; is entitled to receive full
          compensation from buyers who provide false information and/or who can
          take legal action in consequence, including claims for damages.
        </p>
        <p>
          At the time of purchase, there will be a veriﬁcation of the payment
          methods and with the conﬁrmation receipt of the credit card company,
          the buyer will receive a notice that the purchase has been conﬁrmed.
          It is hereby clariﬁed that the charge will be implemented immediately
          after the purchase. Please note that the veriﬁcation company, credit
          bureau are not responsible for the transaction between Airmotive and
          the Buyer, and do not oﬀer any guarantee for the dongle, including,
          without limitation, its quality and/or delivery.
        </p>
        <p>
          The completion of the transaction depends on the available existence
          of the dongle at the time of completion of the purchase. In case the
          dongle is unavailable and/or there is a problem with supply, for any
          reason, Airmotive can inform the Buyer of the cancellation of the
          order. The Buyer can be notiﬁed by email or phone. In such case, the
          Buyer will not have any claim against Airmotive and when purchasing
          the dongle, the Buyer waives said claim. Airmotive reserves the right
          to limit the number of dongles per order.
        </p>
        <p>
          The Buyer may choose to receive the dongle through a direct shipment,
          which implies additional charges (the &quot;Shipping Charge&quot;)
          delivered directly to the Buyer&apos;s home and/or to any other
          destination of their choice. In such case, Airmotive will provide the
          Buyer with the dongle to be delivered to a parcel company, as chosen
          by Airmotive at its sole discretion within approximately 60 days from
          the date of order of the Dongle. Airmotive is not responsible for any
          act or omission of the shipping company, including failure to deliver
          on time, and in that case Airmotive will make every eﬀort to provide
          an alternative product as soon as possible.
        </p>
        <ol start='4'>
          <li>
            <strong>Automatic Payments – Terms and Conditions</strong>
          </li>
        </ol>
        <p style={{ marginLeft: 20, fontSize: 18 }}>
          By enrolling, you authorize Airmotive: (a) to initiate recurring
          automated clearing house (ACH) debit entries or debit card payments
          from the checking or savings account you specify, or (b) to initiate
          recurring charges from your speciﬁed credit card.
        </p>
        <p>
          In order to utilize Airmotive Services or product oﬀerings, the user
          of such Services must pay Airmotive a recurring subscription fee. In
          addition, the user is responsible for any state or local sales taxes
          associated with the Services or product oﬀerings purchased.
        </p>
        <ol start='5'>
          <li>
            <strong>Billing and Payment</strong>
          </li>
        </ol>
        <ol style={{ marginLeft: 20 }}>
          <li>
            When you sign up for Airmotive Service, you agree to pay Airmotive
            all subscription charges associated with the plan you subscribe to
            as described on the Site at the time you subscribe and provide your
            payment information. You also authorize Airmotive, or a third-party
            payment processor that works on our behalf, to charge your chosen
            payment method according to the terms of the plan to which you
            subscribe. The subscription period and the amount and frequency of
            the charges will vary depending on the subscription plan you select.
            Airmotive reserves the right to correct any errors or mistakes that
            it makes even if it has already requested or received payment.
          </li>
          <li>
            To the extent you elect to purchase other Services or product
            oﬀerings we may oﬀer for a fee, you authorize Airmotive to charge
            your chosen payment provider for the Services and/or products you
            purchase. You agree that if Airmotive already has your credit card
            on ﬁle as a result of prior purchases you have made, we may charge
            that credit card for the additional Services/products you purchase.
          </li>
          <p style={{ fontWeight: 'bold' }}>
            Automatic Subscription Renewal and Cancellation
          </p>
          <li>
            <strong>
              AIRMOTIVE PAID MEMBERSHIP SUBSCRIPTIONS WILL CONTINUE INDEFINITELY
              UNTIL CANCELLED BY THE USER. AFTER YOUR INITIAL SUBSCRIPTION
              COMMITMENT PERIOD, AND AGAIN AFTER ANY SUBSEQUENT SUBSCRIPTION
              PERIOD, YOUR SUBSCRIPTION WILL AUTOMATICALLY RENEW FOR AN
              ADDITIONAL EQUIVALENT PERIOD AS THE SUBSCRIPTION TERM YOU
              ORIGINALLY SELECTED AND AT THE SUBSCRIPTION RATE DISCLOSED TO YOU
              ON THE SITE WHEN YOU ORIGINALLY SUBSCRIBED, UNLESS OTHERWISE
              PROVIDED AT THE TIME YOU SUBSCRIBED. WHEN YOU SIGN UP FOR A
              PAYMENT PLAN THAT ALLOWS YOU TO BE CHARGED MONTHLY OVER THE
              SUBSCRIPTION PERIOD AND YOU DECIDE TO CANCEL YOUR SUBSCRIPTION
              DURING THE SUBSCRIPTION PERIOD, YOU ACKNOWLEDGE AND AGREE THAT YOU
              WILL CONTINUE TO BE BILLED FOR THE SUBSCRIPTION ON A MONTHLY BASIS
              UNTIL ITS ORIGINALLY SCHEDULED EXPIRATION DATE. AIRMOTVE SHALL
              BEAR NO LIABILITY OR RESPONSIBILITY FOR ANY LOSSES OF ANY KIND
              THAT YOU MAY INCUR AS A RESULT OF A PAYMENT MADE ON ITEMS
              INCORRECTLY BILLED OR FOR ANY DELAY IN THE ACTUAL DATE ON WHICH
              YOUR ACCOUNT IS DEBITED OR YOUR CREDIT CARD IS CHARGED.
            </strong>
          </li>
          <li>
            You may cancel your paid membership subscription at any time by
            following the instructions on your membership level change page or
            contacting Airmotive’s customer service department at (800) 431-2552
            or support@airmotive.com. If you cancel your subscription, you
            typically will be permitted to use your subscription until the end
            of your then-current subscription term. Your subscription will not
            be renewed after your then-current term expires, but your payment
            method will be charged, and you will be required to pay, any
            cancellation or other fees associated with your early termination
            and disclosed to you at the time you signed up for the subscription
            plan.
          </li>
          <li>
            Airmotive reserves the right to change these terms or terminate this
            Program at any time. Notice may be given on or with your statement
            or by other methods.
          </li>
        </ol>
        <ol start='6'>
          <li>
            <strong>Cancellation</strong>
          </li>
        </ol>
        <p>
          The cancellation will be made in accordance with the provisions of the
          applicable consumer protection legislation and regulation. The return
          of the transaction price in case of cancellation will be made in the
          same way and in accordance with the law.
        </p>
        <p>
          The cancellation of this order must be made in writing by email to the
          Airmotive email address: support@myairmotive.com.
        </p>
        <p>
          Airmotive can cancel a transaction and/or an acquisition in whole or
          in part: in case of an error in the proposal, either in the price of
          the Dongle or in the description of the Dongle; in case of force
          majeure, acts of war, terrorism or hostilities that prevent the
          continuation of the sale, implementation or participation in the sale
          in an appropriate manner; if you discover that the transaction was
          part of an illegal activity of the Buyers and/or any of them and/or a
          third party; if you discover that Buyers can return and sell your
          Dongle to a third party and/or trade with it. The notice of
          cancellation of the sale will be delivered to the Buyer by email
          and/or in writing to the address speciﬁed by the Buyer at the time of
          purchase.
        </p>
        <ol start='7'>
          <li>
            <strong>Restrictions on use</strong>d
          </li>
        </ol>
        <p>
          There are certain behaviors that are strictly prohibited in our
          Service. Please read carefully the following restrictions. Your breach
          of the established provisions may result in the present (at the sole
          discretion of Airmotive) at the end of your access to the site and may
          also expose you to civil and/or criminal liability.
        </p>
        <p>
          You may not do so, either you or any person on your behalf: (a) copy,
          modify, adapt, translate, reverse engineer, decompile or disassemble
          any part of the Content of our Services, in any way, public display,
          representation or distribute them; (b) make use of the Content on the
          Service for any purpose, or replicate and/or copy the Content without
          the prior written consent of Airmotive; (c) create a browser or border
          environment around the Airmotive content (without frames or online
          links); (d) interfere with or violate the privacy rights of another
          visitor of another Site or of the user, or collect or collect
          personally identiﬁable information about visitors or users of our
          services without their express consent, including the use of any
          &quot;robot&quot;, &quot; spider &quot;, search application of the
          site or any part of it, including Airmotive databases, or the recovery
          application or parts of it, including the data index or other device
          or manual or automatic process to recover, index or extract data; (e)
          defame, abuse, harass, stalk, threaten or violate the legal rights of
          others; (f) transmit or make available, in connection with the
          services, viruses, worms, Trojan horses, time bombs, web viruses,
          spyware or any other code, ﬁle or computer program that may or is
          intended to damage or hijack the operation of any hardware, software
          or telecommunications equipment, or any other potentially harmful,
          disruptive or invasive code or component; (g) interfere with or
          interrupt the operation of the services, or the servers or networks
          that host the services, or disobey any requirement, procedure, policy
          or regulation of said servers or networks; (h) sell, license, make
          non-personal use or exploit for any commercial purpose any use or
          access to the Content of the Services without the express consent of
          the Company, including the use of any Content therein; (i) frame or
          reﬂect any part of the Services without the prior express
          authorization of Airmotive; (j) create a database by downloading and
          systematically storing all or part of the Content of the Service; (k)
          forward the data generated from the Services without the prior written
          consent of Airmotive; (l) transfer and/or assign the password of the
          user&apos;s account, even temporarily, to a third party; or (m) use
          the Services for any illegal, immoral or unauthorized purpose.
        </p>
        <ol start='8'>
          <li>
            <strong>Privacy policy</strong>
          </li>
        </ol>
        <p>
          Airmotive respects your privacy and is committed to protecting the
          information you share with it. We believe you have a right to know our
          practices and our procedures regarding the information that Airmotive
          collects and uses when you use the Services. The privacy policy,
          practices and type of information collected from Airmotive are
          described in our Privacy Policy, available here:
          Airmotive.app/privacy_policy.html.
        </p>
        <ol start='9'>
          <li>
            <strong>Intellectual property rights</strong>
          </li>
        </ol>
        <p>
          The Company grants you a limited, personal, non-exclusive,
          non-assignable, non-negotiable license, which can not be used to issue
          sublicenses, and which may be canceled in full, to use: (a) the
          Application on your smartphone or on a telephone intelligent that is
          under your control; (b) the web application; (c) the Site, subject to
          the terms and conditions of this agreement. These terms and conditions
          give you no rights in the Application and / or the Web Application and
          / or the Site or in connection with this, rather than a limited right
          to use it in accordance with the Terms; and (d) the OBD Dongle and the
          intellectual property provided are in accordance with the terms and
          conditions set forth in this agreement. The Airmotive trademarks and
          logo and all other property identiﬁers used by Airmotive in relation
          to the Services (the &quot;Airmotive Trademarks&quot;) are all
          trademarks and/or trade names of the Company, whether or not they are
          registered. All other trademarks, trade names, identifying marks and
          logos that may appear in the Services belong to the owners of their
          rights (&quot;Trademarks of third parties&quot;). No right, license or
          interest is granted for the Airmotive Trademarks or third-party
          trademarks, and therefore you will avoid using any of those trademarks
          unless such use has been speciﬁcally authorized in the Terms. The
          services, and each and every one of the corresponding intellectual
          property rights, including, among others, inventions, patents and
          patent applications, trademarks, trade names, logos, materials
          protected by copyright, graphics, text, images, designs (including the
          &quot;look and feel&quot; of the Service &quot;, speciﬁcations,
          methods, procedures, information, know-how, algorithms, data,
          technical data, interactive features, source and object code, ﬁles,
          interface and trade secrets, whether or not they are registered or can
          be registered or not (collectively, &quot;Intellectual
          Property&quot;), are owned and / or licensed by Airmotive and are
          subject to copyright and other intellectual property rights applicable
          under USA laws, foreign laws and international conventions. You can
          not copy, distribute, display, publicly execute, make available to the
          public, reduce to readable form, decompile, assemble, adapt,
          sublicense, make any commercial use, sell, rent, lend, process,
          compile, reverse engineer, combine with other software, translate,
          modify or create derivative works of any material subject to
          Airmotive&apos;s property rights, including the intellectual property
          of Airmotive, by you or by any person on your behalf, in any way or by
          any means, unless it is expressly permitted in the terms.
        </p>
        <p>
          You are hereby prohibited from removing, separating or removing the
          disconnection of content from all notices of copyright, restrictions
          and signs indicating property rights of Airmotive or its licensors,
          including [®], copyright mark [ ©] or registered trademark [™]
          contained in accompanying the Content included in the Services, and
          you represent and warrant that you will comply with all applicable
          laws in this regard.
        </p>
        <ol start='10'>
          <li>
            <strong>Availability</strong>
          </li>
        </ol>
        <p>
          As our customer, your actual Service area, network availability,
          coverage and quality may vary based on a number of factors, including
          but not limited to, network capacity, terrain, weather, or if your
          Device no longer supports network technologies available. Outages and
          interruptions in Service may occur, and speed of Service varies.
          Devices also have varying speed capabilities and may connect to
          diﬀerent networks depending on technology. Even within coverage areas
          and with broadband-capable devices, network changes, traﬃc volume,
          outages, technical limitations, signal strength, obstructions,
          weather, and other conditions may impact speeds and service
          availability.
        </p>
        <p>
          Where the network is lightly loaded in relation to available capacity,
          a customer whose data is prioritized below other data traﬃc will
          notice little, if any, eﬀect from having lower priority. At times and
          locations where the network is heavily loaded in relation to available
          capacity, however, these customers will likely see signiﬁcant
          reductions in data speeds, especially if they are engaged in
          data-intensive activities. Customers should be aware that these
          practices may occasionally result in speeds below those typically
          experienced on LTE networks.
        </p>
        <ol start='11'>
          <li>
            <strong>Changes to our services</strong>
          </li>
        </ol>
        <p>
          Airmotive reserves the right to modify, correct, amend, improve, make
          any other change or interrupt, temporarily or permanently, the
          services (or any part of it, including, among others, the content)
          without prior notice, in any moment and at its sole discretion. You
          agree that Airmotive will not be liable to you or any third party for
          any modiﬁcation, suspension or interruption of services or the content
          included in our services.
        </p>
        <ol start='12'>
          <li>
            <strong>Disclaimer and warranties</strong>
          </li>
        </ol>
        <p>
          AIRMOTIVE DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING USE,
          EXPRESS OR IMPLIED, INCLUDING SERVICES PROVIDED BY THIRD PARTIES, AS
          WELL AS THE CONTENT GENERATED BY THE USER (AS DEFINED BELOW) OR THE
          RESULTS OF THE USE OF THE SERVICES AND / OR THE DONGLE. AIRMOTIVE
          SHALL NOT BE LIABLE FOR ANY DAMAGES, INCLUDING, BUT NOT LIMITED TO,
          DIRECT, INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY
          KIND, WHETHER OR NOT CAUSED BY CONSEQUENCE OR IN CONNECTION WITH THE
          USE OF THE SERVICES, INCLUDING SERVICES AND ASSISTANCE. ARE PROVIDED
          OR SOLD BY THIRD PARTIES, INCLUDING THROUGH THE SERVICES OR CONTENT
          GENERATED BY THE USER, OR ANY OTHER INFORMATION THAT BEGINS OF THE USE
          OF THE SERVICES, WHETHER THE COMPANY HAD INFORMED THE USER OF SUCH
          POSSIBLE DAMAGE.
        </p>
        <p>
          THE SERVICES, INCLUDING WITHOUT LIMITATION, ANY CONTENT AVAILABLE ON
          THE SERVICES ARE PROVIDED &quot;AS IS,&quot; WITHOUT ANY WARRANTY OF
          ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
          WARRANTIES OF TITLE OR NON-INFRINGEMENT OR IMPLIED WARRANTIES OF USE,
          MERCHANTABILITY OR SUITABILITY FOR A PARTICULAR PURPOSE OR USE.
          AIRMOTIVE DISCLAIMS AND DOES NOT OFFER REPRESENTATIONS OR WARRANTIES
          REGARDING THE ACCURACY, QUALITY, AVAILABILITY, RELIABILITY,
          SUITABILITY, COMPLETENESS, ACCURACY, UTILITY OR EFFECTIVENESS OF ANY
          CONTENT AVAILABLE IN THE SERVICES. USE OF THE SERVICES, THE CONTENT IS
          AT YOUR OWN RISK AND AIRMOTIVE WILL HAVE NO LIABILITY RELATED TO SUCH
          USE.
        </p>
        <p>
          AIRMOTIVE DOES NOT WARRANT THAT THE OPERATION OF THE SERVICES IS OR
          WILL BE SECURE, ACCURATE, COMPLETE, UNINTERRUPTED, ERROR-FREE, OR FREE
          OF VIRUSES, WORMS, OTHER HARMFUL COMPONENTS OR OTHER PROGRAM
          LIMITATIONS.
        </p>
        <p>
          THIS IS HEREBY CLEAR THAT AIRMOTIVE IS NOT RESPONSIBLE FOR THE NATURE,
          QUALITY OR PERFORMANCE OF DONGLE. HOWEVER, IN THE EVENT THAT THE
          DONGLE RECEIVED IS DAMAGED, AIRMOTIVE WILL USE COMMERCIALLY REASONABLE
          EFFORTS TO PROVIDE A REPLACEMENT PRODUCT AS SOON AS POSSIBLE.
        </p>
        <ol start='13'>
          <li>
            <strong>Limitation of liability</strong>
          </li>
        </ol>
        <p>
          IN NO EVENT SHALL AIRMOTIVE, INCLUDING ITS OFFICERS, DIRECTORS,
          SHAREHOLDERS, EMPLOYEES, SUBCONTRACTORS AND THEIR AGENTS, BE LIABLE
          FOR ANY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DIRECT, INDIRECT,
          SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY KIND, WHETHER OR
          NOT ACTION OF CONTRACT, NEGLIGENCE OR OTHER LATE ACTIONS RESULTING
          FROM OR RESULTING FROM THE SERVICES, THE APPLICATION, THE CONTENT AND
          THE AID PROVIDED OR SOLD BY OTHER USERS OR THE USE OR INABILITY TO USE
          THE SERVICES, THE CONTENT, THE LOSS OF THE COMMERCE FUND OR PROFITS,
          THE PERFORMANCE OR NON-COMPLIANCE OF AIRMOTIVE TO MAKE UNDER THESE
          TERMS, ANY OTHER ACT OR OMISSION OF AIRMOTIVE FOR ANY OTHER CAUSE; OR
          BASED ON BREACH OF WARRANTY, BREACH OF CONTRACT, NEGLIGENCE, STRICT
          LIABILITY OR ANY OTHER LEGAL THEORY, REGARDLESS OF WHETHER AIRMOTIVE
          HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>
        <ol start='14'>
          <li>
            <strong>Compensation</strong>
          </li>
        </ol>
        <p>
          You agree to defend, indemnify and hold AIRMOTIVE harmless from and
          against any and all claims, damages, liabilities, losses, liabilities,
          costs, debts and expenses (including, among others, attorneys&apos;
          fees) that arise from: (a) your use of the services; (b) your
          violation of any term of these Terms; (c) any damage of any kind,
          whether direct, indirect, special or consequential, that may cause a
          third party to relate to your use of the Services (including your
          violation of the rights of a third party); and (d) any claim for
          ﬁnancial resources or other compensation due to a decision made or not
          taken or any action that has been taken or has not been made based on
          the Content, result or any other data resulting from the use of the
          Services.
        </p>
        <ol start='15'>
          <li>
            <strong>Amendments to the Terms</strong>
          </li>
        </ol>
        <p>
          Airmotive may change the Terms from time to time, in its sole
          discretion and without prior notice, and therefore requests all Users
          to revisit this page often. We will notify you of substantial changes
          to these Terms by changing the link to the Terms on the Service and /
          or by sending an email regarding such changes to the email address you
          provided during registration. Such substantial changes will take eﬀect
          seven (7) days after such notice has been provided in the Services or
          sent by email. Otherwise, all other changes to these Terms will become
          eﬀective as of the date indicated in &quot;Last Review&quot; and your
          continued use of the Services as of the last revised date will
          constitute acceptance of these changes, and you agree to be bound to
          those changes.
        </p>
        <ol start='16'>
          <li>
            <strong>Termination of our Services operation</strong>
          </li>
        </ol>
        <p>
          At any time, Airmotive may block your access to our Services and/or
          temporarily or permanently limit your access to our Services, in its
          sole discretion, in addition to any other resources that may be
          available to Airmotive under any applicable law. Such Airmotive
          actions may be taken if Airmotive considers that it has breached any
          of these Terms in any way.
        </p>
        <p>
          In addition, Airmotive may, at any time, at its sole discretion,
          suspend the operation of our Services or part of them, temporarily or
          permanently, without prior notice. You accept and acknowledge that
          Airmotive assumes no responsibility with respect to, or in connection
          with the termination of, our operation of Services and the loss of any
          information. The provisions of the Intellectual Property, Disclaimer
          and Guarantees, Limitation of Liability, Compensation and General
          sections will remain in force after the termination or expiration of
          the Terms.
        </p>
        <ol start='17'>
          <li>
            <strong>Links to third-party sites</strong>
          </li>
        </ol>
        <p>
          Our Services may contain links to websites or resources not belonging
          to Airmotive (&quot;Third Party Sites&quot;), whether or not provided
          by Airmotive. Those linked sites are provided solely for your
          convenience. You hereby acknowledge that these linked sites are not
          under the control of Airmotive and are not responsible for the
          availability of such sites or external resources, and do not endorse
          and are not responsible for any advertising content, products or other
          information contained there or available of such linked sites or any
          link contained in a linked site. In addition, you acknowledge and
          agree that Airmotive will not be responsible or liable, directly or
          indirectly, for any damage or loss caused or allegedly caused by or in
          connection with the use or reliance on such content, goods or services
          available on or through such linked sites or resources. Most
          third-party sites contain legal documents about the privacy policy of
          the terms of use, and we recommend that you carefully read those
          documents before using those sites.
        </p>
        <ol start='18'>
          <li>
            <strong>Suppliers of general purpose platforms</strong>
          </li>
        </ol>
        <p>
          As you can download the Application from a third-party platform (for
          example, App Store), a service provider or a distributor (the
          &quot;Platform Providers&quot;), your use of the Application may also
          be subject to the use rules of the Application. Providers of the
          Platform in connection with your use of the Application (&quot;Rules
          of Use&quot;). Please note that the rules of use may apply to you and
          it is your responsibility to determine, while the rules of use govern
          your use of the Application. You agree to comply with all the rules of
          use of the platform that the providers apply to you. The Rules of use
          may, in some cases, prevail over other rules, including the terms of
          this agreement. You hereby declare and warrant that you are not
          precluded from downloading and/or using the Application in accordance
          with any law or in accordance with the Terms. Any download and/or use
          of the Application by any person that is prohibited by law or by the
          Terms is strictly prohibited. You accept and accept that the Platform
          Providers have no responsibility for the Application or the Content of
          it. You acknowledge that the Platform Providers are not committed to
          providing maintenance or assistance services in accordance with the
          Application.
        </p>
        <ol start='19'>
          <li>
            <strong>
              Special conditions related to the assets of third parties
            </strong>
          </li>
        </ol>
        <p>
          The Services may use or include any software developed by third
          parties, ﬁles and components subject to the terms and conditions of
          the license or open source license of a third party (&quot;Third Party
          Components&quot;). These conditions do not apply to the third-party
          components that accompany or are included in the application and the
          company is not responsible for them. You acknowledge that the Company
          is not the author, owner or licensor of third-party components. The
          company eliminates the responsibility and responsibility of any type,
          express or implicit, for the quality, capacity, operation, performance
          or suitability of any component of third parties. Absolutely the
          application or any part of it (except the elements of third parties
          contained in it) will be considered open source, public domain or
          publicly available.
        </p>
        <ol start='20'>
          <li>
            <strong>User generated content</strong>
          </li>
        </ol>
        <p>
          The Service allows you to upload, publish, publish and make available
          your own copyrighted materials such as literary works, texts, images,
          photos, videos, strips and any other proprietary material, including,
          among others, all materials by the Third-Party Providers (the
          &quot;User generated content&quot;). When using the Services, be sure
          to maintain and respect the intellectual property, personal property
          and privacy policy of third parties, for third parties who are
          entitled to the Content generated by the user that the User has loaded
          into the Services. Airmotive will not be responsible nor will be
          responsible, directly or indirectly, for any damage, loss, cost or
          expense that the User may suﬀer due to the Content generated by the
          user.
        </p>
        <p>
          As long as your User-generated Content is subject to the applicable
          copyright law, such User-generated Content will at all times remain,
          and to the extent permitted by law, your sole and exclusive property.
          You accept and declare that the Content generated by the user is not
          conﬁdential. The User understands that they are solely responsible for
          the Content generated by the user and for the results or consequences,
          whether express or implicit, of the load of the Content generated by
          the user to the Services. The User declares and guarantees that the
          Content generated by the user is true, exact and complete. You
          represent and warrant that you are the legitimate owner of the Content
          generated by the user who uploads, or who has (and will continue to
          have) all the necessary licenses, rights, consents and permissions of
          the legitimate owners of such User-generated Content, which are
          necessary to use and allow the Company to use the Content generated by
          the user that you upload and that such User-generated Content does not
          infringe the copyrights or other rights of third parties (including,
          among others, the privacy policy, publication rights, copyright or any
          other intellectual property).
        </p>
        <p>
          You will be responsible for having the required consent under any law
          related to the publication of personal information of other users, and
          you must comply with any state or federal law in relation to the User
          Generated Content. You expressly accept that the Content generated by
          the user will not contain promotions, advertisements, contests or
          unauthorized lotteries.
        </p>
        <p>
          You agree that you will not post or upload any User Generated Content
          that contains illegal content that you own, post or upload in the
          country in which you reside or that it would be unlawful for the
          Company to use or possess in connection with the Service (including,
          among others, content that is: defamatory, pornographic, obscene,
          harassing, threatening, insulting or fraudulent).
        </p>
        <p>
          It is clariﬁed that, although Airmotive does not have the obligation
          to ﬁlter, edit or supervise the Content generated by the user, the
          Company reserves the right to do so, in its sole discretion, and to
          eliminate or edit any Content generated by the user without prior
          notice, at any time and in its sole discretion. You will be solely
          responsible, in its extension, for making backup copies and changing
          all the Content generated by the user published in the Services.
        </p>
        <p>
          When you upload, publish, or make available any Content generated by
          the user on the Platform or use such User-generated Content, you
          grant: (a) to the company an irrevocable, perpetual, non-exclusive,
          royalty-free, transferable right , assignable, sublicense and
          worldwide license to use, reproduce, distribute, transmit, make
          derivative works, display, copy, make available to the public and
          perform that content generated by the user, together with details,
          trademarks, service marks and logos , in relation to the Platform,
          whether through the Internet, any mobile device or any other type, in
          any media format and through any media channel known today and
          developed in the future; (b) To any User of the Services, a
          non-exclusive and royalty-free license to enter User-generated Content
          and make any private and non-commercial use thereof.
        </p>
        <p>
          Airmotive may use and post any part of the User generated Content
          without permission and at its sole discretion. You understand and
          agree that you will not have the right to prevent any transfer of
          Content generated by the user to third parties. Airmotive will not be
          responsible for any use of Content generated by the user by third
          parties. In addition, it waives any moral rights it may have with
          respect to the Content generated by the user and agrees never to
          demand or assign any rights over the Content generated by the user.
        </p>
        <p>
          Airmotive will not be responsible nor will it have any consequence
          derived from the loading, publication, presentation and making
          available of any User Content generated by the user or any third
          party, nor of any damage or loss. Airmotive will not be liable for any
          error, mistake, defamation, deragatory term, or pornography to which
          you or a third party may be exposed.
        </p>
        <ol start='21'>
          <li>
            <strong>Agent of misconduct and copyright</strong>
          </li>
        </ol>
        <p>
          We care for your safety and well-being. If you believe that a user,
          including external Suppliers, acted inappropriately, including, but
          not limited to, oﬀensive, violent or inappropriate sexual behavior,
          immediately inform that person to the appropriate authorities and to
          us.
        </p>
        <p>
          The Company respects the intellectual property rights of others. If
          you believe that your work has been copied in a manner that
          constitutes copyright infringement, please provide the following
          information in writing to the company&apos;s copyright agent: (a) a
          physical or electronic signature of an authorized person to act on
          behalf of the copyright owner; (b) a description of the work protected
          by copyright that you claim has been violated; (c) a description of
          the material that you claim to be infringing or being subject to
          infringing activity and that must be removed or access to which must
          be disabled, and suﬃcient information to allow the Company to locate
          the material (including URL or screenshot) of said infringing
          activity); (d) information so that the Company can contact you, such
          as address, telephone number and email address; (e) a statement that
          you have a good faith belief that use of the material in the manner
          complained of is not authorized by the copyright owner, its agent, or
          the law; and (f) a statement that the information in the notiﬁcation
          is accurate and, under penalty of perjury, you are the copyright owner
          or authorized to act on behalf of the owner of a copyright that is
          allegedly infringed . The copyright agent of the company can be
          contacted through Contact Us on the website.
        </p>
        <ol start='22'>
          <li>
            <strong>General</strong>
          </li>
        </ol>
        <p>
          These terms are not interpreted, nor will they be construed to create
          any relationship of association, joint venture, employer-employee,
          agency or franchisor-franchisee between the parties hereto. Any claim
          related to the Services or the use of the Service will be governed and
          construed in accordance with the USA laws, without reference to its
          principles of conﬂict of laws. Any dispute arising out of or related
          to your use of the Services will be presented, and you hereby accept
          the exclusive jurisdiction and jurisdiction of the competent courts of
          California. If it is determined that any provision of these Terms is
          unlawful, void or unenforceable for any reason, such provision shall
          be deemed severable from these Terms and shall not aﬀect the validity
          and enforceability of any remaining provision. You may not assign,
          sublicense or transfer in any way any or all of your rights or
          obligations under these Terms without the prior express written
          consent of Airmotive. No waiver of any of the parties to any breach or
          breach shall be considered a waiver of any prior or subsequent breach
          or default. Any heading, title or title of the section included in
          this document is inserted only as a matter of convenience, and in no
          way deﬁnes or explains any section or provision thereof. These Terms
          are the complete terms and conditions between you and Airmotive
          related to the subject matter hereof and supersede any and all prior
          or contemporaneous written or oral agreements or agreements between
          you and Airmotive. Notiﬁcations to you can be made by email or by
          mail. Airmotive may also provide notices of changes to these Terms or
          other matters, displaying such notices or providing links to such
          notices. Without limitation, you agree that a printed version of these
          Terms and any notice given in electronic form will be admissible in
          judicial or administrative proceedings based on or related to these
          Terms to the same extent and subject to the same conditions as other
          commercial documents and records originally generated and kept in
          print. Updates to the Service may be automatically downloaded from
          time to time to your smartphone and OBD Dongle (the
          &quot;Updates&quot;). The updates are designed to improve, enrich and
          develop the services by means of: errors and bug ﬁxes, advanced
          options, new features and adjustments to match the new hardware and
          software versions. You represent and agree to accept the Updates from
          the Company and allow the Company to automatically push and install
          the Updates directly on your smartphone, as part of your use of the
          Services. Hereby, it allows the company to use push notiﬁcations as a
          means of communicating with the user through the smartphone.
        </p>
        <p>&nbsp;</p>
        <ol start='23'>
          <li>
            <strong>
              For information, questions or error notiﬁcation, contact:
            </strong>
          </li>
        </ol>
        <p>
          If you have any questions (or comments) about the Terms, you can send
          an email to Airmotive at: support@myairmotive.app or use the
          Application by clicking on the &quot;Contact Us&quot; button, and
          Airmotive will make an eﬀort to respond within a framework of
          reasonable time. By contacting the Company, you warrant and agree that
          you are free to do so, and that you do not provide Airmotive with
          information that violates the intellectual rights of a third party
          (the &quot;Information&quot;). Notwithstanding the foregoing, all
          rights, including intellectual property, derived from the
          communication will be the property of the Company and will be
          considered as conﬁdential material of Airmotive. It is clariﬁed that
          any use of the Information will be done at the sole discretion of the
          Company, and the Company is not obligated to use all or part of this
          Information.
        </p>
        <p>
          You are not required to be a registered user to visit the Site.
          However, if you wish to contact us through the Services, you must
          complete and complete the Contact form. The Contact form requires your
          email address, and the rest of the information is optional. In
          addition, you can contact us through the Contact button in the
          Application.
        </p>
      </GridItem>
    </GridContainer>
  );
}

Terms.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(style)(Terms);
