/* eslint-disable no-console */
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Icon from '@material-ui/core/Icon';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import LocationOn from '@material-ui/icons/LocationOn';
import { setCurrentTrip } from 'actions/auth';
import {
  carNotifications,
  getTripHistory,
  setCurrentCar,
  getBattery
} from 'actions/dashboard';
import { getCarList } from 'actions/mycars';
import { getData, getTripDetail } from 'actions/overview';
import image_route_brake from 'assets/img/icn_breaking.png';
import image_trip_end from 'assets/img/icn_online_map.png';
import icon_battery from 'assets/img/icon_battery.png';
import image_route_accel from 'assets/img/icon_route_accel.png';
import image_route_idle from 'assets/img/icon_route_idle.png';
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardInfo from 'components/CustomCard/CardInfo.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import NotificationsTable from 'components/Table/NotificationsTable.jsx';
import Table from 'components/Table/Table.jsx';
import moment from 'moment';
import 'moment/locale/es';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import {
  GoogleMap,
  Marker,
  Polyline,
  withGoogleMap,
  withScriptjs
} from 'react-google-maps';
import { withTranslation } from 'react-i18next';
import Moment from 'react-moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, lifecycle, withProps } from 'recompose';
import storage from 'utils/storage';
import unitConverter from 'variables/unitConverter';

const CENTER = { lat: 32.8058822, lng: -117.119443 }; // Default center

const CustomSkinMap = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyD0N4e75HLlrE05-d9me1ejseeq1wOaoy4',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `200px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  lifecycle({
    UNSAFE_componentWillReceiveProps() {
      this.setState({
        zoomToPolyline: map => {
          let fitbound = false;
          if (map) {
            const bounds = new window.google.maps.LatLngBounds();
            map.props.children.forEach(child => {
              if (R.pathOr([], ['props', 'path'])(child).length > 1) {
                child.props.path.forEach(dot => {
                  bounds.extend(
                    new window.google.maps.LatLng(dot.lat, dot.lng)
                  );
                  fitbound = true;
                });
              }
            });
            if (fitbound) map.fitBounds(bounds);
          }
        }
      });
    }
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    ref={props.zoomToPolyline}
    defaultCenter={CENTER}
    defaultZoom={12}
    defaultOptions={{
      scrollwheel: false,
      zoomControl: true,
      scaleControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      styles: [
        {
          featureType: 'water',
          stylers: [{ saturation: 43 }, { lightness: -11 }, { hue: '#0088ff' }]
        },
        {
          featureType: 'road',
          elementType: 'geometry.fill',
          stylers: [{ hue: '#ff0000' }, { saturation: -100 }, { lightness: 99 }]
        },
        {
          featureType: 'road',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#808080' }, { lightness: 54 }]
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.fill',
          stylers: [{ color: '#ece2d9' }]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry.fill',
          stylers: [{ color: '#ccdca1' }]
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#767676' }]
        },
        {
          featureType: 'road',
          elementType: 'labels.text.stroke',
          stylers: [{ color: '#ffffff' }]
        },
        { featureType: 'poi', stylers: [{ visibility: 'off' }] },
        {
          featureType: 'landscape.natural',
          elementType: 'geometry.fill',
          stylers: [{ visibility: 'on' }, { color: '#b8cb93' }]
        },
        { featureType: 'poi.park', stylers: [{ visibility: 'on' }] },
        {
          featureType: 'poi.sports_complex',
          stylers: [{ visibility: 'on' }]
        },
        { featureType: 'poi.medical', stylers: [{ visibility: 'on' }] },
        {
          featureType: 'poi.business',
          stylers: [{ visibility: 'simplified' }]
        }
      ]
    }}
  >
    <Polyline
      path={props.markers}
      defaultOptions={{ strokeColor: '#29B7DA', strokeWeight: 4 }}
    />
    {props.markers.length > 0 && (
      <Marker
        key={props.markers[0].id}
        position={{ lat: props.markers[0].lat, lng: props.markers[0].lng }}
        icon={{
          url: image_trip_end,
          scaledSize: new window.google.maps.Size(15, 20)
        }}
      />
    )}
  </GoogleMap>
));

const calendarStringsEs = {
  lastDay: '[Ayer a las] LT',
  sameDay: '[Hoy a las] LT',
  lastWeek: '[el], dddd [a las] LT',
  sameElse: 'L [a las] LT'
};

const calendarStringsEn = {
  lastDay: '[Yesterday at] LT',
  sameDay: '[Today at] LT',
  lastWeek: '[Last], dddd [at] LT',
  sameElse: 'L [at] LT'
};

class DashBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trip: {},
      index: 0
    };
  }

  componentDidMount() {
    if (this.props.currentCar !== undefined && this.props.currentCar.id > 0) {
      this.props.getData(this.props.currentCar.id);
      this.props.setCarNotifications(this.props.currentCar.id);
    } else {
      this.props.getData();
    }
    this.props.getCarList();
  }

  // TODO IMPROVEMENT
  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      tripHistory,
      currentTrip,
      currentCar,
      setCarNotifications,
      carNotifications,
      lastTrip
    } = nextProps;
    if (
      carNotifications.length < 1 &&
      currentCar !== null &&
      currentCar !== undefined
    ) {
      setCarNotifications(currentCar.id);
    }
    let trip = {};
    if (currentTrip !== 0) {
      trip =
        tripHistory.find(trip => trip.id === currentTrip) || tripHistory[0];
    } else {
      trip = tripHistory[0] || {};
      if (trip.id) {
        nextProps.getTripDetail(trip.id);
        nextProps.setCurrentTrip(trip.id);
      }
    }
    if (prevState.trip.carid !== currentCar.id) {
      nextProps.getTripDetail(lastTrip.id);
      return { trip: lastTrip };
    }
    return { trip };
  }

  carProblems = data => {
    const { t } = this.props;
    if (data.checkEngine) {
      return t('CheckEngine');
    }
    if (data.dtcCodes.length > 0) {
      return (
        t('DtcProblem') +
        R.pathOr('unknow', ['dtcCodes', 0, 'code'])(data) +
        ' - ' +
        (localStorage.getItem('language') === 'Español'
          ? R.pathOr('unknow', ['dtcCodes', 0, 'es'])(data)
          : R.pathOr('unknow', ['dtcCodes', 0, 'en'])(data))
      );
    }
    return t('IssuesMessage');
  };

  batteryLevel = voltage => {
    const { t } = this.props;
    if (voltage > 13) {
      return t('Great');
    }
    if (voltage > 12.1) {
      return t('Good');
    }
    if (voltage > 11.9) {
      return t('Low');
    }
    return t('Bad');
  };

  handleChangeIndex = index => {
    if (index === 3) this.props.getBattery(this.props.currentCar.id);
    this.setState({ index });
  };

  tripDetail = id => {
    if (id === undefined) return;
    this.props.getTripDetail(id);
    this.props.setCurrentTrip(id);
  };

  getLanguage() {
    return storage.getLanguage() === 'English'
      ? calendarStringsEn
      : calendarStringsEs;
  }

  changeCar = event => {
    const { value } = event.target;
    const car = R.filter(data => data.id === value, this.props.carList);
    this.props.setCurrentCar(car[0]);
    this.props.setCarNotifications(car[0].id);
  };

  render() {
    if (localStorage.getItem('language') === 'Español') {
      moment.locale('es');
    } else {
      moment.locale('en');
    }
    const {
      classes,
      tripHistory,
      markers,
      battery,
      t,
      currentCar,
      lastTrip,
      carList,
      carNotifications
    } = this.props;
    const { trip, index } = this.state;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={3}>
            <Card action onClick={() => this.handleChangeIndex(0)}>
              <CardHeader icon>
                <CardIcon color='success'>
                  <Icon>directions_car</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>
                  {t('Car')}{' '}
                  {currentCar.device &&
                  currentCar.device.state.toLowerCase() === 'disconnected'
                    ? 'Offline'
                    : 'Online'}
                </p>
                <FormControl
                  className={classes.formControl}
                  style={{ width: '60%', alignSelf: 'right' }}
                >
                  <Select
                    value={currentCar.id}
                    onChange={this.changeCar}
                    inputProps={{
                      name: 'carSelect',
                      id: 'carSelect'
                    }}
                  >
                    {carList.map((data, key) => (
                      <MenuItem key={key} value={data.id}>
                        {data.nickname ? data.nickname : 'New Vehicle'}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </CardHeader>
              <CardBody plain />
              <CardFooter stats>
                <div className={classes.stats}>
                  {currentCar.device && (
                    <Moment calendar={this.getLanguage()}>
                      {R.pathOr(null, ['device', 'time'])(currentCar)}
                    </Moment>
                  )}
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card action onClick={() => this.handleChangeIndex(1)}>
              <CardHeader icon>
                <CardIcon color='info'>
                  <LocationOn />
                </CardIcon>
                <p className={classes.cardCategory}>{t('LastTripDone')}</p>
                <p className={classes.cardTitle}>
                  {lastTrip.end_address || t('None')}
                </p>
              </CardHeader>
              <CardBody plain />
              <CardFooter stats>
                <div className={classes.stats}>
                  {lastTrip.position_off && (
                    <Moment calendar={this.getLanguage()}>
                      {lastTrip.position_off && lastTrip.position_off.time}
                    </Moment>
                  )}
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card action onClick={() => this.handleChangeIndex(3)}>
              <CardHeader icon>
                <CardIcon color='infoBattery'>
                  <img src={icon_battery} alt='Battery' width='50%' />
                </CardIcon>
                <p className={classes.cardCategory}>{t('BatteryLife')}</p>
                <p className={classes.cardTitle}>
                  {this.batteryLevel(currentCar.batteryVoltage)}{' '}
                  {currentCar.batteryVoltage}V
                </p>
              </CardHeader>
              <CardBody plain />
              <CardFooter stats>
                <div className={classes.stats}>
                  {currentCar.device && (
                    <Moment calendar={this.getLanguage()}>
                      {currentCar.device && currentCar.device.time}
                    </Moment>
                  )}
                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <Card action onClick={() => this.handleChangeIndex(2)}>
              <CardHeader icon>
                <CardIcon color='yellow'>
                  <Icon>build</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>{t('Issues')}</p>
                <PerfectScrollbar>
                  <p className={classes.cardTitle}>
                    {currentCar.dtcCodes && this.carProblems(currentCar)}
                  </p>
                </PerfectScrollbar>
              </CardHeader>
              <CardBody plain />
              <CardFooter stats>
                <div className={classes.stats}>
                  {currentCar.historicalData && (
                    <Moment calendar={this.getLanguage()}>
                      {currentCar.historicalData &&
                        currentCar.historicalData.update_time}
                    </Moment>
                  )}
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <CardInfo
              car={currentCar}
              trip={trip}
              option={index}
              battery={battery}
            />
          </GridItem>
          <GridItem xs={12} sm={8} md={8}>
            <Card>
              <CardHeader color='success'>
                <CustomSkinMap markers={markers} />
              </CardHeader>
              <CardBody plain>
                <GridContainer>
                  <GridItem xs={4} sm={4} md={4}>
                    <p className={classes.cardTitleCenter}>
                      <img src={image_route_brake} alt='brake' />
                      {trip.rapid_decelerations}
                    </p>
                    <p className={classes.cardTitleCenter}>{t('Abrupt')}</p>
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <p className={classes.cardTitleCenter}>
                      <img src={image_route_idle} alt='idle' />
                      {trip.idle_exceed_total}
                    </p>
                    <p className={classes.cardTitleCenter}>{t('Idling')}</p>
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <p className={classes.cardTitleCenter}>
                      <img src={image_route_accel} alt='accel' />
                      {trip.rapid_accelerations}
                    </p>
                    <p className={classes.cardTitleCenter}>
                      {t('RapidAccels')}
                    </p>
                  </GridItem>
                </GridContainer>
              </CardBody>
              {trip.position_on && (
                <CardFooter chart>
                  <GridContainer>
                    <GridItem xs={6} sm={6} md={6}>
                      <div className={classes.tripStats}>
                        <p>{t('Distance')}</p>
                        <hr className={classes.dotLine} />
                        <p
                          className={classes.textAlignRight}
                          style={{ fontWeight: 'bold' }}
                        >
                          {unitConverter.kmToMiles(trip.driving_distance)}{' '}
                          {unitConverter.kmUnit()}
                        </p>
                      </div>
                      <div className={classes.tripStats}>
                        <p>{t('Duration')}</p>
                        <hr className={classes.dotLine} />
                        <p
                          className={classes.textAlignRight}
                          style={{ fontWeight: 'bold' }}
                        >
                          <Moment diff={trip.position_on.time} unit='minutes'>
                            {trip.position_off.time}
                          </Moment>{' '}
                          MIN
                        </p>
                      </div>
                      <div className={classes.tripStats}>
                        <p>{t('MaxSpeed')}</p>
                        <hr className={classes.dotLine} />
                        <p
                          className={classes.textAlignRight}
                          style={{ fontWeight: 'bold' }}
                        >
                          {unitConverter.kmToMilesNew(
                            trip.max_speed.toFixed(1)
                          )}{' '}
                          {unitConverter.perHourUnit()}
                        </p>
                      </div>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      <div className={classes.tripStats}>
                        <p>{t('AVGSpeed')}</p>
                        <hr className={classes.dotLine} />
                        <p
                          className={classes.textAlignRight}
                          style={{ fontWeight: 'bold' }}
                        >
                          {trip.avg_speed &&
                            unitConverter.kmToMilesNew(
                              trip.avg_speed.toFixed(1)
                            )}{' '}
                          {unitConverter.perHourUnit()}
                        </p>
                      </div>
                      <div className={classes.tripStats}>
                        <p>{t('EngineSpeed')}</p>
                        <hr className={classes.dotLine} />
                        <p
                          className={classes.textAlignRight}
                          style={{ fontWeight: 'bold' }}
                        >
                          {trip.max_rpm === null ? 0 : trip.max_rpm} RPM
                        </p>
                      </div>
                      <div className={classes.tripStats}>
                        <p>{t('Efficiency')}</p>
                        <hr className={classes.dotLine} />
                        <p
                          className={classes.textAlignRight}
                          style={{ fontWeight: 'bold' }}
                        >
                          {trip.driving_distance > 0
                            ? unitConverter.efficiency(trip)
                            : 0}{' '}
                          {unitConverter.perLiterUnit()}
                        </p>
                      </div>
                    </GridItem>
                  </GridContainer>
                </CardFooter>
              )}
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <p style={{ fontWeight: 'bold' }}> {t('TripHistory')} </p>
                <hr />
                <Table
                  tableHeaderColor='primary'
                  onClickDetail={this.tripDetail}
                  tableHead={[
                    t('TripStart'),
                    t('TripEnd'),
                    t('Duration'),
                    t('Details')
                  ]}
                  tableData={tripHistory}
                />
                {tripHistory.length === 0 && (
                  <CircularProgress className={classes.progress} size={50} />
                )}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <p style={{ fontWeight: 'bold' }}> {t('CarNotifications')} </p>
                <hr />
                <NotificationsTable
                  tableHeaderColor='primary'
                  // onClickDetail={this.tripDetail}
                  tableHead={[t('Date'), t('Event')]}
                  tableData={carNotifications}
                />
                {carNotifications.length === 0 && (
                  <CircularProgress className={classes.progress} size={50} />
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

DashBoard.propTypes = {
  battery: PropTypes.object,
  carList: PropTypes.arrayOf(PropTypes.object),
  cars: PropTypes.arrayOf(PropTypes.object),
  markers: PropTypes.arrayOf(PropTypes.object),
  tripHistory: PropTypes.arrayOf(PropTypes.object),
  setCarNotifications: PropTypes.func,
  carNotifications: PropTypes.arrayOf(PropTypes.object),
  getCarList: PropTypes.func,
  getData: PropTypes.func,
  getTripDetail: PropTypes.func,
  getTripHistory: PropTypes.func,
  setCurrentCar: PropTypes.func,
  setCurrentTrip: PropTypes.func,
  t: PropTypes.func,
  currentTrip: PropTypes.number,
  classes: PropTypes.object.isRequired,
  currentCar: PropTypes.object,
  lastTrip: PropTypes.object,
  getBattery: PropTypes.func
};

function mapStateToProps(state) {
  return {
    cars: R.pathOr([], ['overview', 'cars'])(state),
    markers: R.pathOr({}, ['overview', 'tripDetail'])(state),
    lastTrip: R.pathOr({}, ['overview', 'lastTrip'])(state),
    tripHistory: R.pathOr([], ['overview', 'tripHistory'])(state),
    currentCar: R.pathOr(null, ['dashboard', 'currentCar'])(state),
    carNotifications: R.pathOr([], ['dashboard', 'carNotifications'])(state),
    currentTrip: R.pathOr(0, ['auth', 'currentTrip'])(state),
    battery: R.pathOr({}, ['auth', 'battery'])(state),
    carList: R.pathOr([], ['mycars', 'carList'])(state)
  };
}

const mapDispatchToProps = {
  getCarList: getCarList,
  getData: getData,
  getTripDetail: getTripDetail,
  getTripHistory: getTripHistory,
  setCurrentTrip: setCurrentTrip,
  setCurrentCar: setCurrentCar,
  setCarNotifications: carNotifications,
  getBattery: getBattery
};

export default compose(
  withStyles(dashboardStyle),
  withRouter,
  withTranslation('translations'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DashBoard);
