/**
 * Clebber
 *
 * Created by Edgar Lopez on 08/30/18.
 * Copyright © 2018 intqd. All rights reserved.
 */

import {
  OVERVIEW_DATA,
  OVERVIEW_CURRENT_CAR,
  OVERVIEW_GET_TRIP_DETAIL,
  OVERVIEW_TRIP_HISTORY,
  OVERVIEW_TRIP_DETAIL_POSITION
} from "./constants";

export function getData(cid) {
  return { type: OVERVIEW_DATA, cid };
}

export function getTripDetail(tid) {
  return { type: OVERVIEW_GET_TRIP_DETAIL, tid };
}

export function setData(data) {
  return { type: OVERVIEW_CURRENT_CAR, data };
}

export function setTrips(data) {
  return { type: OVERVIEW_TRIP_HISTORY, data };
}

export function setTripDetailPosition(data) {
  return { type: OVERVIEW_TRIP_DETAIL_POSITION, data };
}
