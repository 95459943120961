import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Slide from '@material-ui/core/Slide';
import withStyles from '@material-ui/core/styles/withStyles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import { getUser } from 'actions/auth';
import { deleteCar, getCarList, updateCar } from 'actions/mycars';
import icon_imei from 'assets/img/icon_imei.png';
import logo from 'assets/img/logo_white@2x.png';
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import moment from 'moment';
import 'moment/locale/es';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { withTranslation } from 'react-i18next';
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import unitConverter from 'variables/unitConverter';

function Transition(props) {
  return <Slide direction='up' {...props} />;
}

class MyCars extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      progress: false,
      user: {},
      carID: 0,
      editCar: undefined,
      vehicle: {}
    };
  }

  componentDidMount() {
    this.props.getCarList();
    this.props.getUser();
  }

  static getDerivedStateFromProps(nextProps) {
    const { user } = nextProps;
    if (user) {
      return {
        user: user
      };
    }
    return null;
  }

  componentDidUpdate() {
    const { successDelete } = this.props;
    const { openDialog } = this.state;
    if (successDelete && openDialog) {
      this.handleCancel();
    }
  }

  handleClickOpen = carID => {
    this.setState({ openDialog: true, carID });
  };

  handleOk = () => {
    let { vehicle } = this.state;
    if (unitConverter.isMilles()) {
      vehicle.odometer = (parseFloat(vehicle.odometer) / 0.621371) * 1000;
    } else {
      vehicle.odometer = parseFloat(vehicle.odometer) * 1000;
    }
    vehicle.odometer = parseInt(vehicle.odometer, 10);
    vehicle.visible = vehicle.visible ? 1 : 0;
    this.setState({ progress: true });
    this.props.updateCar(vehicle);
  };

  handleCancel = () => {
    this.setState({ openDialog: false, vehicle: {}, progress: false });
  };

  handleChange = name => event => {
    let { vehicle } = this.state;
    if (name === 'visible') vehicle[name] = event.target.checked;
    else vehicle[name] = event.target.value;
    this.setState({ vehicle });
  };

  cardConnected = (car, onClickEdit, key) => {
    const { classes, t } = this.props;
    return (
      <GridItem key={key} xs={10} sm={10} md={10} lg={10}>
        <Card carconnected>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <IconButton
                  color='secondary'
                  className={classes.button}
                  aria-label='edit vehicle'
                  style={{ float: 'right' }}
                  onClick={() => {
                    onClickEdit();
                  }}
                >
                  <Icon
                    style={{
                      color: '#DD2853'
                    }}
                  >
                    edit_icon
                  </Icon>
                </IconButton>
              </GridItem>
              <GridItem xs={3} sm={3} md={3}>
                <Icon style={{ fontSize: '3.0rem', color: '#FFFFFF' }}>
                  directions_car
                </Icon>
              </GridItem>
              <GridItem xs={9} sm={9} md={9}>
                <p
                  className={classes.cardTitleWhite}
                  style={{ fontWeight: 'bold' }}
                >
                  {car.nickname}
                </p>
                <p className={classes.cardStatusWhite}>
                  {car.device.state.toLowerCase() === 'disconnected'
                    ? 'Offline'
                    : 'Online'}
                </p>
                <p
                  className={classes.cardRowTextWhite}
                  style={{ fontWeight: 'bold' }}
                >
                  {t('DateAdded')}
                  <span
                    className={classes.textAlignRight}
                    style={{ fontWeight: 'normal' }}
                  >
                    <Moment format=' D MMM, YYYY'>{car.time}</Moment>
                  </span>
                </p>
                <hr />
                <p
                  className={classes.cardRowTextWhite}
                  style={{ fontWeight: 'bold' }}
                >
                  {t('License')}
                  <span
                    className={classes.textAlignRight}
                    style={{ fontWeight: 'normal' }}
                  >
                    {car.plate}
                  </span>
                </p>
                <hr />
                <p
                  className={classes.cardRowTextWhite}
                  style={{ fontWeight: 'bold' }}
                >
                  VIN
                  <span
                    className={classes.textAlignRight}
                    style={{ fontWeight: 'normal' }}
                  >
                    {car.vin}
                  </span>
                </p>
                <hr />
                <p
                  className={classes.cardRowTextWhite}
                  style={{ fontWeight: 'bold' }}
                >
                  {t('Odometer')}({unitConverter.radiusUnit()})
                  <span
                    className={classes.textAlignRight}
                    style={{ fontWeight: 'normal' }}
                  >
                    {unitConverter.kmToMiles(car.odometer)}
                  </span>
                </p>
                <hr />
                <p
                  className={classes.cardRowTextWhite}
                  style={{ fontWeight: 'bold' }}
                >
                  <img src={icon_imei} alt='IMEI' height='8' /> IMEI
                  <span
                    className={classes.textAlignRight}
                    style={{ fontWeight: 'normal' }}
                  >
                    {car.device.imei}
                  </span>
                </p>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    );
  };

  cardDisconnected = (car, onClickEdit, key) => {
    const { classes, t } = this.props;
    return (
      <GridItem key={key} xs={10} sm={10} md={10} lg={10}>
        <Card cardisconnected>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <IconButton
                  color='secondary'
                  className={classes.button}
                  aria-label='edit vehicle'
                  style={{ float: 'right' }}
                  onClick={() => {
                    onClickEdit();
                  }}
                >
                  <Icon
                    style={{
                      color: '#DD2853'
                    }}
                  >
                    edit_icon
                  </Icon>
                </IconButton>
              </GridItem>
              <GridItem xs={3} sm={3} md={3}>
                <Icon style={{ fontSize: '3.0rem', color: '#FFFFFF' }}>
                  directions_car
                </Icon>
              </GridItem>
              <GridItem xs={9} sm={9} md={9}>
                <p
                  className={classes.cardTitleWhite}
                  style={{ fontWeight: 'bold' }}
                >
                  {car.nickname}
                </p>
                <p className={classes.cardStatusWhite}>
                  {car.device.state.toLowerCase() === 'disconnected'
                    ? 'Offline'
                    : 'Online'}
                </p>
                <p
                  className={classes.cardRowTextWhite}
                  style={{ fontWeight: 'bold' }}
                >
                  {t('DateAdded')}
                  <span
                    className={classes.textAlignRight}
                    style={{ fontWeight: 'normal' }}
                  >
                    <Moment format=' D MMM, YYYY'>{car.time}</Moment>
                  </span>
                </p>
                <hr />
                <p
                  className={classes.cardRowTextWhite}
                  style={{ fontWeight: 'bold' }}
                >
                  {t('License')}
                  <span
                    className={classes.textAlignRight}
                    style={{ fontWeight: 'normal' }}
                  >
                    {car.plate}
                  </span>
                </p>
                <hr />
                <p
                  className={classes.cardRowTextWhite}
                  style={{ fontWeight: 'bold' }}
                >
                  VIN
                  <span
                    className={classes.textAlignRight}
                    style={{ fontWeight: 'normal' }}
                  >
                    {car.vin}
                  </span>
                </p>
                <hr />
                <p
                  className={classes.cardRowTextWhite}
                  style={{ fontWeight: 'bold' }}
                >
                  {t('Odometer')}({unitConverter.kmUnit()})
                  <span
                    className={classes.textAlignRight}
                    style={{ fontWeight: 'normal' }}
                  >
                    {unitConverter.kmToMiles(car.odometer)}
                  </span>
                </p>
                <hr />
                <p
                  className={classes.cardRowTextWhite}
                  style={{ fontWeight: 'bold' }}
                >
                  <img src={icon_imei} alt='IMEI' height='8' /> IMEI
                  <span
                    className={classes.textAlignRight}
                    style={{ fontWeight: 'normal' }}
                  >
                    {car.device.imei}
                  </span>
                </p>
                <Button
                  size='sm'
                  color='info'
                  onClick={() => this.handleClickOpen(car.id)}
                >
                  {t('Delete')}
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    );
  };

  NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              value: values.value
            }
          });
        }}
        thousandSeparator={true}
      />
    );
  }

  render() {
    if (localStorage.getItem('language') === 'Español') {
      moment.locale('es');
    } else {
      moment.locale('en');
    }
    const { classes, carList, t } = this.props;
    const { user, openDialog, progress, vehicle } = this.state;
    return (
      <div>
        <Dialog
          open={openDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle id='alert-dialog-slide-title'>
            {t('EditCar')}
          </DialogTitle>
          <DialogContent>
            {progress && (
              <CircularProgress className={classes.progress} size={50} />
            )}
            <FormGroup>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <TextField
                  id='name'
                  label={t('Name')}
                  value={R.pathOr('', ['nickname'])(vehicle)}
                  onChange={this.handleChange('nickname')}
                  margin='normal'
                  style={{ width: '90%' }}
                  variant='outlined'
                />
                <FormControlLabel
                  control={
                    <Switch
                      color='primary'
                      checked={vehicle.visible === true}
                      onChange={this.handleChange('visible')}
                      value='visible'
                    />
                  }
                  label={t('Active')}
                  labelPlacement='start'
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <TextField
                  id='license'
                  label={t('License')}
                  value={R.pathOr('', ['plate'])(vehicle)}
                  onChange={this.handleChange('plate')}
                  margin='normal'
                  style={{ width: '90%', marginRight: 15 }}
                  variant='outlined'
                />
                <TextField
                  id='odometer'
                  label={t('Odometer')}
                  value={R.pathOr(undefined, ['odometer'])(vehicle)}
                  type='text'
                  InputProps={{
                    inputComponent: this.NumberFormatCustom,
                    endAdornment: (
                      <InputAdornment position='end'>
                        {unitConverter.radiusUnit()}
                      </InputAdornment>
                    )
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={this.handleChange('odometer')}
                  margin='normal'
                  style={{ width: '90%' }}
                  variant='outlined'
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <TextField
                  id='make'
                  label={t('Maker')}
                  value={R.pathOr('', ['maker'])(vehicle)}
                  onChange={this.handleChange('maker')}
                  margin='normal'
                  style={{ width: '90%', marginRight: 15 }}
                  variant='outlined'
                />
                <TextField
                  id='model'
                  label={t('Model')}
                  value={R.pathOr('', ['model'])(vehicle)}
                  onChange={this.handleChange('model')}
                  margin='normal'
                  style={{ width: '90%' }}
                  variant='outlined'
                />
              </div>
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color='info'>
              {t('Cancel')}
            </Button>
            <Button onClick={this.handleOk} color='info'>
              {t('OK')}
            </Button>
          </DialogActions>
        </Dialog>
        <Card>
          <CardHeader color='cars'>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '10px 20px'
              }}
            >
              <div>
                <h4
                  className={classes.cardTitleWhite}
                  style={{ fontWeight: 'bold' }}
                >
                  {user.name || user.username || ''}
                </h4>
                <p className={classes.cardCategoryWhite}>{user.email || ''}</p>
                <p className={classes.cardCategoryWhite}>{user.phone || ''}</p>
              </div>
              <img src={logo} alt='logo' height='80' />
            </div>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <h4
                style={{
                  width: '50%',
                  paddingLeft: 15,
                  color: '#001B45',
                  marginBottom: 0,
                  fontWeight: 'bold'
                }}
              >
                {t('Connected')}
              </h4>
              <h4
                style={{
                  flex: 1,
                  color: '#001B45',
                  marginBottom: 0,
                  fontWeight: 'bold'
                }}
              >
                {t('All')}
              </h4>
              <div style={{ width: '50%' }}>
                {carList.length > 0 &&
                  carList.map((car, key) => {
                    return car.device.state.toLowerCase() !== 'disconnected'
                      ? this.cardConnected(
                          car,
                          () => {
                            this.setState({
                              vehicle: {
                                id: car.id,
                                plate: car.plate,
                                nickname: car.nickname,
                                odometer: unitConverter.kmToMiles(car.odometer),
                                maker: car.maker,
                                model: car.model,
                                visible: car.visible === 1 ? true : false
                              }
                            });
                            this.handleClickOpen();
                          },
                          key
                        )
                      : null;
                  })}
              </div>
              <div style={{ width: '50%' }}>
                {carList.length > 0 &&
                  carList.map((car, key) => {
                    return car.device.state.toLowerCase() === 'disconnected'
                      ? this.cardDisconnected(
                          car,
                          () => {
                            this.setState({
                              vehicle: {
                                id: car.id,
                                plate: car.plate,
                                nickname: car.nickname,
                                odometer: unitConverter.kmToMiles(car.odometer),
                                maker: car.maker,
                                model: car.model,
                                visible: car.visible === 1 ? true : false
                              }
                            });
                            this.handleClickOpen();
                          },
                          key
                        )
                      : this.cardConnected(
                          car,
                          () => {
                            this.setState({
                              vehicle: {
                                id: car.id,
                                plate: car.plate,
                                nickname: car.nickname,
                                odometer: unitConverter.kmToMiles(car.odometer),
                                maker: car.maker,
                                model: car.model,
                                visible: car.visible === 1 ? true : false
                              }
                            });
                            this.handleClickOpen();
                          },
                          key
                        );
                  })}
              </div>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    carList: state.mycars.carList,
    successDelete: state.mycars.successDelete,
    user: state.auth.user
  };
}

const mapDispatchToProps = {
  getCarList: getCarList,
  getUser: getUser,
  updateCar: updateCar,
  deleteCar: deleteCar
};

MyCars.propTypes = {
  classes: PropTypes.object.isRequired,
  carList: PropTypes.arrayOf(PropTypes.object),
  user: PropTypes.object,
  getCarList: PropTypes.func,
  getUser: PropTypes.func,
  deleteCar: PropTypes.func,
  updateCar: PropTypes.func,
  successDelete: PropTypes.bool,
  t: PropTypes.object
};

export default compose(
  withStyles(dashboardStyle),
  withTranslation('translations'),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MyCars);
