import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import tableStyle from 'assets/jss/material-dashboard-react/components/tableStyle';
import Button from 'components/CustomButtons/Button.jsx';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import unitConverter from 'variables/unitConverter';

class CustomTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 5
    };
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const {
      classes,
      tableHead,
      tableData,
      tableHeaderColor,
      onClickDetail,
      t
    } = this.props;

    const { rowsPerPage, page } = this.state;
    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
      <div className={classes.tableResponsive}>
        <Table className={classes.table}>
          {tableHead !== undefined ? (
            <TableHead className={classes[tableHeaderColor + 'TableHeader']}>
              <TableRow>
                {tableHead.map((prop, key) => {
                  return (
                    <TableCell
                      className={
                        classes.tableCell + ' ' + classes.tableHeadCell
                      }
                      style={{ color: 'BLACK' }}
                      key={key}
                    >
                      {prop}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {tableData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => {
                return (
                  <TableRow key={row.id}>
                    <TableCell className={classes.tableCell}>
                      {row.tag}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.carname}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.address}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {unitConverter.radius(row.data.radius)}{' '}
                      {unitConverter.radiusUnit()}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.notification ? t('Activated') : t('Deactivated')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Button
                        size='sm'
                        color='info'
                        onClick={() => onClickDetail(row)}
                      >
                        {t('View')}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={6}
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                // ActionsComponent={TablePaginationActionsWrapped}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    );
  }
}

CustomTable.defaultProps = {
  tableHeaderColor: 'gray'
};

CustomTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    'warning',
    'primary',
    'danger',
    'success',
    'info',
    'yellow',
    'gray'
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.object),
  onClickDetail: PropTypes.func,
  t: PropTypes.func
};

export default compose(
  withStyles(tableStyle),
  withTranslation('translations')
)(CustomTable);
