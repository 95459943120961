import { call, put, takeLatest } from 'redux-saga/effects';
import clebber from '../api';

import {
  MYCARS_LIST,
  MYCARS_DELETE,
  MYCARS_DELETE_ERROR,
  MYCARS_DELETE_SUCCESS,
  MYCARS_UPDATE,
  MYCARS_UPDATE_ERROR,
  MYCARS_UPDATE_SUCCESS
} from '../actions/constants';
import { setCarList } from '../actions/mycars';

export function* carsList() {
  try {
    const carList = yield call(clebber.getCarList);

    if (carList) {
      yield put(setCarList(carList));
    }
  } catch (error) {
    console.log('Error:', error);
  }
}

export function* deleteCar(params) {
  try {
    const success = yield call(clebber.deleteCar, params.cid);
    if (success) {
      yield put({ type: MYCARS_LIST });
      yield put({ type: MYCARS_DELETE_SUCCESS });
      yield put({ type: MYCARS_DELETE });
    }
  } catch (error) {
    yield put({ type: MYCARS_DELETE_ERROR });
  }
}

export function* updateCar(params) {
  try {
    const success = yield call(clebber.updateCar, params.params);
    if (success) {
      yield put({ type: MYCARS_LIST });
      yield put({ type: MYCARS_UPDATE_SUCCESS });
      yield put({ type: MYCARS_UPDATE });
    }
  } catch (error) {
    yield put({ type: MYCARS_UPDATE_ERROR });
  }
}

export const mycarsSaga = [
  takeLatest(MYCARS_LIST, carsList),
  takeLatest(MYCARS_DELETE, deleteCar),
  takeLatest(MYCARS_UPDATE, updateCar)
];
