import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Menu from '@material-ui/icons/Menu';
import HeaderLinks from './HeaderLinks';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import headerStyle from 'assets/jss/material-dashboard-react/components/headerStyle.jsx';

function Header({ ...props }) {
  function makeBrand() {
    var name;
    props.routes.map(prop => {
      if (prop.path === props.history.location.pathname) {
        name = prop.navbarName;
      }
      return null;
    });
    return name;
  }
  const { classes, color, t } = props;
  const appBarClasses = classNames({
    [' ' + classes[color]]: color
  });
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>{t(makeBrand())}</div>
        <Hidden smDown implementation='css'>
          <HeaderLinks />
        </Hidden>
        <Hidden mdUp implementation='css'>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  handleDrawerToggle: PropTypes.func,
  t: PropTypes.func,
  routes: PropTypes.func,
  history: PropTypes.object
};

export default compose(
  withStyles(headerStyle),
  withTranslation('translations')
)(Header);
