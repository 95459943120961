/**
 * Clebber
 *
 * Created by Edgar Lopez on 10/19/18.
 * Copyright © 2018 intqd. All rights reserved.
 */

import { call, put, takeLatest } from 'redux-saga/effects';
import clebber from '../api';
import {
  GEOFENCE_DATA,
  GEOFENCE_SET_LIST,
  GEOFENCE_ADD,
  GEOFENCE_DELETE,
  GEOFENCE_UPDATE
} from '../actions/constants';

export function* getGeofences(params) {
  try {
    const geofenceList = yield call(clebber.getGeofences, params.cid);
    if (geofenceList) {
      yield put({
        type: GEOFENCE_SET_LIST,
        geofenceList: geofenceList
      });
    }
  } catch (error) {
    console.log('there was an error:', error);
  }
}

export function* deleteGeofence(params) {
  try {
    const geofenceList = yield call(clebber.deleteGeofence, params.data.gid);
    if (geofenceList) {
      yield put({
        type: GEOFENCE_DATA,
        params: params.data
      });
    }
  } catch (error) {}
}

export function* addGeofence(params) {
  try {
    const add = yield call(clebber.addGeofence, params.data);
    if (add.status === 200) {
      yield put({
        type: GEOFENCE_DATA,
        params: params.data
      });
    }
  } catch (error) {}
}

export function* updateGeofence(params) {
  try {
    const update = yield call(clebber.updateGeofence, params.data);
    if (update.status === 200) {
      yield put({
        type: GEOFENCE_DATA,
        params: params.data
      });
    }
  } catch (error) {}
}

export const geofenceSaga = [
  takeLatest(GEOFENCE_DATA, getGeofences),
  takeLatest(GEOFENCE_ADD, addGeofence),
  takeLatest(GEOFENCE_DELETE, deleteGeofence),
  takeLatest(GEOFENCE_UPDATE, updateGeofence)
];
