import { setLenguage } from 'actions/dashboard';
import { Button, Popover } from 'antd';
import 'antd/dist/antd.css';
import 'antd/lib/popover/style/css';
import 'assets/vendors/flag/sprite-flags-24x24.css';
import 'assets/vendors/gaxon/styles.css';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import languageData from 'variables/languageData';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
      hovered: false,
      locale:
        localStorage.getItem('language') === 'Español'
          ? languageData[1]
          : languageData[0]
    };
  }
  handleClickChange = visible => {
    this.setState({
      clicked: visible,
      hovered: false
    });
  };

  languageMenu = () => (
    <div className='gx-popover-lang-scroll'>
      <ul className='gx-sub-popover'>
        {languageData.map(language => {
          return (
            <li
              className='gx-media gx-pointer'
              key={JSON.stringify(language)}
              onClick={() => {
                this.props.setLenguage(language.name);
                this.setState({
                  locale: language,
                  clicked: false,
                  hovered: false
                });
              }}
            >
              <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
              <span className='gx-language-text'>{language.name}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );

  render() {
    const { t } = this.props;
    const { locale, clicked } = this.state;
    return (
      <div style={{ position: 'absolute', right: 0, top: 0, margin: 10 }}>
        <Popover
          placement='bottomRight'
          title={t('Language')}
          trigger='click'
          content={this.languageMenu()}
          visible={clicked}
          onVisibleChange={this.handleClickChange}
        >
          <Button>
            <span className='gx-pointer gx-flex-row gx-align-items-center'>
              <i className={`flag flag-24 flag-${locale.icon}`} />
              <span className='gx-pl-2 gx-language-name'>{locale.name}</span>
              <i className='icon icon-chevron-down gx-pl-2' />
            </span>
          </Button>
        </Popover>
      </div>
    );
  }
}
Index.propTypes = {
  t: PropTypes.func,
  setLenguage: PropTypes.func
};

function mapStateToProps() {
  return {};
}

const mapDispatchToProps = {
  setLenguage: setLenguage
};

export default compose(
  withTranslation('translations'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Index);
