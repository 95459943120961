import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
//import Slide from '@material-ui/core/Slide';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import { loggedIn, loginRequest } from 'actions/auth';
import logo from 'assets/img/img_logo1.png';
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Language from 'components/Language';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import storage from 'utils/storage';

/*function Transition(props) {
  return <Slide direction='up' {...props} />;
}*/

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      submitted: false,
      openDialog: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.checkLoggedIn();
    // eslint-disable-next-line react/prop-types
    this.props.i18n.changeLanguage(storage.getLanguage().slice(0, 2));
  }

  componentDidUpdate() {
    const { error } = this.props;
    if (error && this.state.submitted) {
      this.setState({ submitted: false, openDialog: true });
    }
  }

  goForgotPassword = () => {
    this.props.push('/forgot');
  };

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { username, password } = this.state;
    if (username && password) {
      this.setState({ submitted: true });
      this.props.loginRequest({ username, password });
    }
  }

  handleOk = () => {
    this.setState({ openDialog: false });
  };

  render() {
    const { classes, modal, t } = this.props;
    const { submitted, openDialog } = this.state;
    return (
      <div>
        <Language></Language>
        <Dialog
          open={openDialog}
          //TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle id='alert-dialog-slide-title'>{modal.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              {t(modal.content)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleOk} color='info'>
              {t('OK')}
            </Button>
          </DialogActions>
        </Dialog>
        <GridContainer
          style={{
            justifyContent: 'space-around',
            margin: '0 0 !important'
          }}
        >
          <GridItem xs={12} sm={6} md={4} lg={3}>
            <Card profile style={{ backgroundColor: '#FFFF' }}>
              <CardBody>
                <img
                  src={logo}
                  alt='logo'
                  height='60'
                  style={{ marginTop: 20 }}
                />
                <form
                  onSubmit={this.handleSubmit}
                  className={classes.container}
                  noValidate
                  autoComplete='off'
                  style={{
                    display: 'grid',
                    marginBottom: '20px',
                    marginTop: 25
                  }}
                >
                  {submitted && (
                    <CircularProgress className={classes.progress} size={50} />
                  )}
                  <TextField
                    required
                    id='name'
                    name='username'
                    label={t('UsernameEmail')}
                    className={classes.textField}
                    onChange={this.handleChange}
                    margin='normal'
                  />
                  <TextField
                    required
                    id='password'
                    name='password'
                    label={t('Password')}
                    type='password'
                    className={classes.textField}
                    onChange={this.handleChange}
                    margin='normal'
                  />
                </form>
                <span style={{ fontWeight: '500' }}>
                  {t('ForgotPassword')}{' '}
                </span>
                <Button
                  color='transparent'
                  className={classes.title}
                  onClick={this.goForgotPassword}
                  style={{
                    display: 'inline-block',
                    margin: 0,
                    padding: 0,
                    opacity: 100
                  }}
                >
                  <p
                    style={{
                      color: '#29B7DA',
                      fontWeight: '500',
                      textTransform: 'none',
                      margin: 0
                    }}
                  >
                    {t('ClickHere')}
                  </p>
                </Button>
              </CardBody>
            </Card>
            <Button fullWidth color='info' onClick={this.handleSubmit}>
              {t('Login')}
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: R.pathOr(false, ['auth', 'error'])(state),
    modal: R.pathOr({}, ['auth', 'modal'])(state)
  };
}

const mapDispatchToProps = {
  loginRequest: loginRequest,
  checkLoggedIn: loggedIn,
  push: push
};

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  loginRequest: PropTypes.func,
  checkLoggedIn: PropTypes.func,
  push: PropTypes.func,
  t: PropTypes.func,
  setLenguage: PropTypes.func,
  error: PropTypes.bool,
  modal: PropTypes.object
};

export default compose(
  withStyles(dashboardStyle),
  withTranslation('translations'),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Login);
