import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import iconStart from 'assets/img/icn_tripstarted.png';
import iconActual from 'assets/img/icn_location.png';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import carOffline from 'assets/img/icn_offline_circle.png';
import carOnline from 'assets/img/icn_online_circle.png';
import pin_disable from 'assets/img/icon_pin_blue.png';
import pin_disable_min from 'assets/img/icon_pin_blue_box.png';
import pin_enable from 'assets/img/icn_online_map.png';
import pin_enable_min from 'assets/img/icn_online_map.png';
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.jsx';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs
} from 'react-google-maps';
import { withTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { compose, lifecycle, withProps, withState } from 'recompose';
import { setCurrentCar } from './../../actions/dashboard';
import { getData } from './../../actions/overview';
const CENTER = { lat: 32.8058822, lng: -117.119443 }; // Default center
const ZOOM = 13;
const CustomSkinMap = compose(
  withStyles(dashboardStyle, { withTheme: true }),
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyD0N4e75HLlrE05-d9me1ejseeq1wOaoy4&libraries=geometry',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: (
      <div style={{ height: `100%`, width: '100%', margin: 0 }} />
    ),
    mapElement: <div style={{ height: `100%` }} />
  }),
  withState('loading'),
  lifecycle({
    componentDidMount() {
      this.setState({ loaded: false });
    },
    UNSAFE_componentWillReceiveProps(props) {
      const { markers, currentCarID, mode } = props;
      const { loaded } = this.state;
      if (loaded) return;
      if (markers.length >= 1 && mode === 1) {
        this.setState({
          zoomToMarkers: map => {
            if (map) {
              const bounds = new window.google.maps.LatLngBounds();
              map.props.children.forEach(child => {
                bounds.extend(
                  new window.google.maps.LatLng(
                    child.props.position.lat,
                    child.props.position.lng
                  )
                );
              });
              map.fitBounds(bounds, 12);
            }
          },
          loading: true
        });
      } else if (markers.length && mode === 2) {
        let mark = undefined;
        for (let i = 0; i < markers.length; i++) {
          if (currentCarID === markers[i].id) {
            mark = markers[i];
            break;
          }
        }
        if (!mark) mark = markers[0];
        const center = {
          lat: mark.position.latitude,
          lng: mark.position.longitude
        };

        this.setState({
          zoomToMarkers: map => {
            if (map) {
              const bounds = new window.google.maps.LatLngBounds();
              bounds.extend(
                new window.google.maps.LatLng(
                  mark.position.latitude,
                  mark.position.longitude
                )
              );
              map.fitBounds(bounds);
            }
          },
          loading: true
        });
        this.setState({ center, loading: true });
      }
    }
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    style={props.style}
    ref={props.zoomToMarkers}
    defaultCenter={CENTER}
    center={props.center}
    defaultZoom={ZOOM}
    defaultOptions={{
      maxZoom: 16,
      scrollwheel: false,
      zoomControl: true,
      scaleControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      styles: [
        {
          featureType: 'water',
          stylers: [{ saturation: 43 }, { lightness: -11 }, { hue: '#0088ff' }]
        },
        {
          featureType: 'road',
          elementType: 'geometry.fill',
          stylers: [{ hue: '#ff0000' }, { saturation: -100 }, { lightness: 99 }]
        },
        {
          featureType: 'road',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#808080' }, { lightness: 54 }]
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.fill',
          stylers: [{ color: '#ece2d9' }]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry.fill',
          stylers: [{ color: '#ccdca1' }]
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#767676' }]
        },
        {
          featureType: 'road',
          elementType: 'labels.text.stroke',
          stylers: [{ color: '#ffffff' }]
        },
        { featureType: 'poi', stylers: [{ visibility: 'off' }] },
        {
          featureType: 'landscape.natural',
          elementType: 'geometry.fill',
          stylers: [{ visibility: 'on' }, { color: '#b8cb93' }]
        },
        { featureType: 'poi.park', stylers: [{ visibility: 'on' }] },
        {
          featureType: 'poi.sports_complex',
          stylers: [{ visibility: 'on' }]
        },
        { featureType: 'poi.medical', stylers: [{ visibility: 'on' }] },
        {
          featureType: 'poi.business',
          stylers: [{ visibility: 'simplified' }]
        }
      ]
    }}
    zoom={ZOOM}
  >
    {props.markers.map(marker => (
      <Marker
        key={marker.id}
        icon={
          props.currentCarID === marker.id
            ? marker.device.state.toLowerCase() !== 'disconnected'
              ? images(pin_enable)
              : images(pin_disable)
            : marker.device.state.toLowerCase() !== 'disconnected'
            ? images(pin_enable_min)
            : images(pin_disable_min)
        }
        zIndex={props.currentCarID === marker.id ? 10 : 0}
        position={{
          lat: marker.position.latitude,
          lng: marker.position.longitude
        }}
        onClick={() => props.onClickMark(marker)}
      />
    ))}
  </GoogleMap>
));

function images(pin, isBig) {
  return isBig
    ? {
        url: pin,
        origin: new window.google.maps.Point(0, 0),
        scaledSize: new window.google.maps.Size(33, 40)
      }
    : {
        url: pin,
        origin: new window.google.maps.Point(0, 0)
      };
}

class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      isDefault: true,
      mode: 1
    };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  componentDidMount() {
    const { currentCar } = this.props;
    this.props.getData(currentCar.id);
  }

  onClickMark = car => {
    if (car === undefined) return;
    this.props.setCurrentCar(car);
  };
  handleChangeMode = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    // eslint-disable-next-line
    const { classes, markers, lastTrip, currentCar, theme, t } = this.props;
    const { mode } = this.state;
    let center = CENTER; // Default center
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div className={classes.resizeMap}>
          <CustomSkinMap
            center={center}
            onClickMark={this.onClickMark}
            markers={markers}
            currentCarID={currentCar.id}
            mode={mode}
          />
        </div>
        <div>
          <Paper className={classes.drawerPaper} elevation={1}>
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator
              }}
              style={{ width: '100%' }}
            >
              <Tab
                disableRipple
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected
                }}
                label={t('SelectedCar')}
              />
              <Tab
                disableRipple
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected
                }}
                label={t('DeviceList')}
              />
            </Tabs>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={this.state.value}
              onChangeIndex={this.handleChangeIndex}
              className={classes.reziseWith}
            >
              <PerfectScrollbar>
                <div
                  style={{
                    backgroundColor: '#F3F3F3',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 10
                  }}
                >
                  <img
                    alt='carState'
                    style={{ height: '4vw', verticalAlign: 'middle' }}
                    src={
                      R.pathOr('disconnected', ['device', 'state'])(
                        currentCar
                      ).toLowerCase() === 'disconnected'
                        ? carOffline
                        : carOnline
                    }
                  />
                  <div style={{ marginLeft: 10 }}>
                    <p style={{ color: '#2B2B2B', fontWeight: 'bold' }}>
                      {currentCar.nickname}
                    </p>
                    <p>
                      {R.pathOr('disconnected', ['device', 'state'])(
                        currentCar
                      ).toLowerCase() === 'disconnected'
                        ? 'Offline'
                        : 'Online'}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'left',
                    paddingLeft: '8%',
                    paddingRight: '8%'
                  }}
                >
                  <div
                    style={{
                      borderBottomColor: 'rgb(43, 43, 43,0.15)',
                      borderBottomWidth: 1,
                      borderStyle: 'solid',
                      borderTopWidth: 0,
                      borderLeftWidth: 0,
                      borderRightWidth: 0,
                      paddingBottom: 0,
                      paddingTop: 0
                    }}
                  >
                    <img
                      src={iconActual}
                      style={{ marginTop: 5 }}
                      alt='iconActual'
                    />
                    <p
                      style={{
                        fontWeight: 'bold',
                        display: 'inline-block',
                        marginLeft: 20,
                        verticalAlign: 'middle',
                        textAlign: 'center',
                        fontSize: '1.5vw'
                      }}
                    >
                      {t('CurrentLocation')}
                    </p>
                    <p style={{ marginLeft: 35, fontSize: '1vw' }}>
                      {lastTrip.end_address}
                    </p>
                  </div>
                  <div
                    style={{
                      borderBottomColor: 'rgb(43, 43, 43,0.15)',
                      borderBottomWidth: 1,
                      borderStyle: 'solid',
                      borderTopWidth: 0,
                      borderLeftWidth: 0,
                      borderRightWidth: 0,
                      paddingBottom: 0,
                      paddingTop: 0
                    }}
                  >
                    <img
                      src={iconStart}
                      style={{ marginTop: 5 }}
                      alt='iconStart'
                    />
                    <p
                      style={{
                        fontWeight: 'bold',
                        display: 'inline-block',
                        marginLeft: 20,
                        verticalAlign: 'middle',
                        textAlign: 'center',
                        fontSize: '1.5vw'
                      }}
                    >
                      {t('LastTripStart')}
                    </p>
                    <p style={{ marginLeft: 35, fontSize: '1vw' }}>
                      {lastTrip.start_address}
                    </p>
                  </div>
                </div>
              </PerfectScrollbar>
              <PerfectScrollbar>
                <form
                  style={{ display: 'flex', flexWrap: 'wrap' }}
                  autoComplete='off'
                >
                  <FormControl
                    variant='filled'
                    style={{ width: '100%', margin: 20 }}
                    fullWidth
                  >
                    <InputLabel htmlFor='filled-mode-simple'>
                      {t('ViewOverview')}
                    </InputLabel>
                    <Select
                      value={mode}
                      onChange={this.handleChangeMode}
                      input={
                        <FilledInput name='mode' id='filled-mode-simple' />
                      }
                    >
                      <MenuItem value={1}>{t('Default')}</MenuItem>
                      <MenuItem value={2}>{t('Single')}</MenuItem>
                    </Select>
                  </FormControl>
                </form>
                {markers.map((car, key) => (
                  <Button
                    key={key}
                    style={{
                      display: 'flex',
                      width: '90%',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      paddingLeft: 35,
                      marginTop: 20
                    }}
                    onClick={() => this.onClickMark(car)}
                  >
                    <img
                      alt='carState'
                      style={{ height: '45px', verticalAlign: 'middle' }}
                      src={
                        R.pathOr('diconnected', ['device', 'state'])(
                          car
                        ).toLowerCase() === 'disconnected'
                          ? carOffline
                          : carOnline
                      }
                    />
                    <div style={{ marginLeft: 10 }}>
                      <p style={{ color: '#2B2B2B', fontWeight: 'bold' }}>
                        {car.nickname}
                      </p>
                      <p>
                        {R.pathOr('disconnected', ['device', 'state'])(
                          car
                        ).toLowerCase() === 'disconnected'
                          ? 'Offline'
                          : 'Online'}
                      </p>
                    </div>
                  </Button>
                ))}
              </PerfectScrollbar>
            </SwipeableViews>
          </Paper>

          {markers.length === 0 && (
            <CircularProgress className={classes.progress} size={50} />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    markers: state.dashboard.markers,
    currentCar: state.dashboard.currentCar,
    lastTrip: state.overview.lastTrip
  };
}

const mapDispatchToProps = {
  getData: getData,
  setCurrentCar: setCurrentCar
};

Overview.propTypes = {
  cars: PropTypes.arrayOf(PropTypes.object),
  markers: PropTypes.arrayOf(PropTypes.object),
  lastTrip: PropTypes.object,
  classes: PropTypes.object.isRequired,
  currentCar: PropTypes.object,
  getData: PropTypes.func,
  setCurrentCar: PropTypes.func,
  theme: PropTypes.object.isRequired
};

export default compose(
  withStyles(dashboardStyle, { withTheme: true }),
  withRouter,
  withTranslation('translations'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Overview);
