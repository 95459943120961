/**
 * Clebber
 *
 * Created by Edgar Lopez on 08/30/18.
 * Copyright © 2018 intqd. All rights reserved.
 */

let localStorage;

// If we're testing, use a local storage polyfill
if (global.process && process.env.NODE_ENV === 'dev') {
  localStorage = require('localStorage');
} else {
  // If not, use the browser one
  localStorage = global.window.localStorage;
}

const storage = {
  getUser() {
    return JSON.parse(localStorage.user);
  },
  saveUser(data) {
    localStorage.setItem('user', JSON.stringify(data.user));
  },
  getToken() {
    return localStorage.token;
  },
  saveToken(data) {
    localStorage.setItem('token', data.access.access);
  },
  deleteAll() {
    localStorage.clear();
    return '';
  },
  isEmpty() {
    return localStorage.token && localStorage.user;
  },
  getLanguage() {
    return localStorage.language || 'English';
  },
  setLanguage(lng) {
    localStorage.setItem('language', lng);
  },
  getUnitSystem() {
    return localStorage.units || 'Miles';
  },
  setUnitSystem(unit) {
    localStorage.setItem('units', unit);
  }
};

export default storage;
