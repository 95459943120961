/* eslint-disable no-console */
import { create } from 'apisauce';
import moment from 'moment';

class ClebberRestApi {
  constructor(authToken) {
    this.api = create({
      baseURL: 'https://x9e2xdch87.execute-api.us-west-1.amazonaws.com/dev',
      // baseurl: 'http://192.168.3.103:3003/',
      headers: {
        // Include as many custom headers as you need
        Authorization: `${authToken}`
      }
    });

    this.api.addRequestTransform(request => {
      request.headers['Authorization'] = localStorage.token;
      // API KEY: vMoviles
      request.headers['x-api-key'] = 'ABECD2500B13A0D076ACB0799CEB93EDA2BF134F';
    });

    this.api.addResponseTransform(response => {
      try {
        if (response.ok === false) {
          switch (response.problem) {
            case 'NETWORK_ERROR':
              response.problem = 'networkerror';
              throw new Error(response.problem);
            case 'CLIENT_ERROR':
              response.ok = true;
              break;
            default:
              response.data = {};
          }
        }
      } catch (error) {
        console.error(error);
      }
    });
  }

  setToken(authToken) {
    this.api.setHeader('Authorization', authToken);
  }

  updateUser(parameters) {
    return this.api.put('/user', parameters);
  }

  login(username, password) {
    return this.api.post('/user/login', { username, password });
  }

  createUser(parameters) {
    return this.api.post('/user', parameters);
  }

  getCarList() {
    return this.api.get('/cars');
  }

  getCarDetail(cid) {
    return this.api.get(`/car/${cid}`);
  }

  updateCar(parameters) {
    return this.api.put(`/car/${parameters.id}`, parameters);
  }

  deleteCar(cid) {
    if (cid === undefined) {
      return false;
    }
    return this.api.delete(`/car/${cid}`).then(response => {
      return response.status === 200;
    });
  }

  getTrips(cid, page) {
    const offset = page * 10;
    return this.api.get(`/car/${cid}/trips?limit=30&offset=${offset}`);
  }

  getTripPosition(tid) {
    return this.api.get(`/trip/${tid}/positions/simple`);
  }

  getNotifications(cid) {
    return this.api.get(`/car/${cid}/notifications?limit=100`);
  }

  getUserNotifications() {
    const offset = parseInt(localStorage.getItem('notificationsSeen'));
    if (offset > 0) {
      return this.api.get(`/user/notifications?offset=${offset}`);
    }
    return this.api.get(`/user/notifications`);
  }

  getCarNotifications(cid) {
    return this.api.get(`/car/${cid}/notifications?limit=100`);
  }

  getNotificationDetail(nid) {
    return this.api.get(`/notification/${nid}`);
  }

  getCarSettings(cid) {
    return this.api.get(`/car/${cid}/settings`);
  }

  getBatteryStatic(cid) {
    const time = moment().utcOffset();
    return this.api.get(`/car/${cid}/battery?time_offset=${time}`);
  }

  getAllGeofences() {
    return this.api.get('/geofences');
  }

  getGeofences(cid) {
    return this.api.get(`/geofences`);
  }

  addGeofence(params) {
    // Add geofence	carid, latitude, longitude, radius(meters), tag, notification
    return this.api.post('/geofence', params);
  }

  updateGeofence(params) {
    // edit geofence	gid, latitude, longitude, radius(meters), tag, notification
    return this.api.put('/geofence', params);
  }

  changePassword(params) {
    return this.api.post('/user/update_password', {
      old_password: params.oldPassword,
      new_password: params.newPassword
    });
  }

  deleteGeofence(gid) {
    // delete geofence gid
    return this.api.delete(`/geofence?gid=${gid}`);
  }

  sendEmailResetPass(params) {
    return this.api.post('/user/forgot_password', params);
  }

  resetPassword(params) {
    return this.api.post('/user/reset_password', params);
  }

  registerDevice(params) {
    return this.api.post('/user/device', params);
  }

  updateDevice(did, params) {
    return this.api.put(`/device/${did}`, params);
  }

  validateEmail(params) {
    return this.api.post(`/user/validate`, params);
  }
}

export default new ClebberRestApi();
