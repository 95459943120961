import withStyles from '@material-ui/core/styles/withStyles';
import logo from 'assets/img/icn_emailsent.png';
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import Language from 'components/Language';

class ForgotSuccess extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit() {
    this.setState({ redirect: true });
  }

  render() {
    const { t, location } = this.props;
    const { redirect } = this.state;
    if (redirect || R.pathOr('', ['state', 'username'])(location) === '')
      return <Redirect to='/forgot' />;
    return (
      <div>
        <Language></Language>
        <GridContainer
          style={{ justifyContent: 'space-around', margin: '0 0 !important' }}
        >
          <GridItem xs={12} sm={6} md={4}>
            <Card profile>
              <CardBody>
                <h3 style={{ color: '#29B7DA' }}>{t('EmailSent')}</h3>
                <img src={logo} alt='logo' style={{ width: '100%' }} />
                <div style={{ display: 'inline-block' }}>
                  {t('EmailSentTo1') + ' '}
                  <p style={{ fontWeight: 'bold', display: 'inline-block' }}>
                    {R.pathOr('', ['state', 'username'])(location)}
                  </p>
                  {' ' + t('EmailSentTo2')}
                </div>
                <p style={{ marginTop: 25 }}>{t('NotMyEmail')}</p>
                <Button
                  color='info'
                  style={{
                    width: '100%'
                  }}
                  onClick={this.handleSubmit}
                >
                  {t('ResendEmail')}
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

const mapDispatchToProps = {};

ForgotSuccess.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func,
  location: PropTypes.object
};

export default compose(
  withStyles(dashboardStyle),
  withTranslation('translations'),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ForgotSuccess);
