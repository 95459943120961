import withStyles from '@material-ui/core/styles/withStyles';
import logo from 'assets/img/img_logo1.png';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import PropTypes from 'prop-types';
import React from 'react';

const style = {
  grid: {
    padding: '0 15px !important',
    justifyContent: 'space-between'
  },
  logo: {
    float: 'right'
  }
};

function Return({ ...props }) {
  return (
    <GridContainer
      style={{ justifyContent: 'space-around', margin: '0 0 !important' }}
    >
      <GridItem>
        <h2>
          <strong>Return Policy</strong>
          <img
            className={props.classes.logo}
            src={logo}
            alt='logo'
            width='450'
            height='134'
          />
        </h2>
        <p>&nbsp;</p>
        <p>
          <strong>Airmotive, LLC (Airmotive)</strong>
        </p>
        <p>
          <strong>Last updated April 18, 2019</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          Thank you for your purchase. We are conﬁdent you will be delighted
          with the Airmotive product,however, if you are not completely satisﬁed
          for any reason, you may return it to us for a full refund or an
          exchange. Please see below for more information on our return policy.
        </p>
        <h4>
          <strong>RETURNS</strong>
        </h4>
        <p>
          All returns must be postmarked within fourteen (14) days of the
          purchase date. All returned items must be in new and unused condition,
          with all labels attached and instructions booklet intact.
        </p>
        <h4>
          <strong>RETURN PROCESS</strong>
        </h4>
        <p>
          To return an item, please email customer service at
          support@myairmotive.com to obtain a Return Merchandise Authorization
          (RMA) number. After receiving an RMA number, place the item securely
          in its original packaging and include the provided return form. Mail
          your return to the following address:
        </p>
        <br />
        <p>AirMotive</p>
        <p>RMA#</p>
        <p>507 W Main Street</p>
        <p>Lexington, IL 61753</p>
        <p>United States</p>
        <br />
        <p>
          Please note, you will be responsible for all return shipping charges
          if returned after 14 days and/or if not postmarked within 24 hours of
          RMA # being issued. We strongly recommend that you add tracking
          services through the carrier you chose to return the product.
        </p>
        <h4>
          <strong>REFUNDS</strong>
        </h4>
        <p>
          After receiving your return and inspecting the condition of your item,
          we will process your return or exchange. Please allow at least seven
          (7) days from the receipt of your item to process your return or
          exchange. We will notify you by email when your return has been
          processed.
        </p>
        <h4>
          <strong>EXCEPTIONS</strong>
        </h4>
        <p>
          For defective or damaged products, please contact us at the customer
          service number below to arrange a refund or exchange.
        </p>
        <p>
          <strong>Please note:</strong> A $35 restocking fee will be charged for
          devices that are not defective.
        </p>
        <h4>
          <strong>QUESTIONS</strong>
        </h4>
        <p>
          If you have any questions concerning our return policy, please contact
          us at:
        </p>
        <p>(800) 431-2552 or support@myairmotive.com</p>
      </GridItem>
    </GridContainer>
  );
}
Return.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(style)(Return);
