import { ConnectedRouter } from 'connected-react-router/immutable';
import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import indexRoutes from 'routes/index.jsx';
import PropTypes from 'prop-types';

const App = ({ history } /* receive history object via props */) => (
  <ConnectedRouter history={history}>
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          {indexRoutes.map((prop, key) => {
            if (prop.redirect)
              return <Redirect from={prop.path} to={prop.to} key={key} />;
            return (
              <Route path={prop.path} component={prop.component} key={key} />
            );
          })}
        </Switch>
      </Suspense>
    </div>
  </ConnectedRouter>
);

App.propTypes = {
  history: PropTypes.object
};

export default App;
