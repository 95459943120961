import withStyles from '@material-ui/core/styles/withStyles';
import logo from 'assets/img/img_logo1.png';
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { validateEmail } from 'actions/auth';
import Language from 'components/Language';

class ValidateEmail extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.validateEmail({
      token: R.pathOr(false, ['match', 'params', 'token'])(this.props)
    });
  }
  render() {
    const { success, t, history } = this.props;
    return (
      <div>
        <Language></Language>
        <GridContainer
          style={{ justifyContent: 'space-around', margin: '0 0 !important' }}
        >
          <GridItem xs={12} sm={6} md={4}>
            <Card profile>
              <CardBody>
                <img src={logo} alt='logo' style={{ width: '100%' }} />
                <h4 style={{ marginTop: 20 }}>{t('EmailConfirmation')}</h4>
                <p>{success ? t('EmailConfirmed') : t('EmailNotConfirmed')}</p>
                <Button
                  type='submit'
                  color='info'
                  style={{
                    width: '100%'
                  }}
                  onClick={() => {
                    history.push('/login');
                  }}
                >
                  {t('Return')}
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    success: state.auth.success
  };
}

const mapDispatchToProps = {
  validateEmail: validateEmail
};

ValidateEmail.propTypes = {
  classes: PropTypes.object.isRequired,
  validateEmail: PropTypes.func,
  t: PropTypes.func,
  history: PropTypes.func,
  success: PropTypes.bool
};

export default compose(
  withStyles(dashboardStyle),
  withTranslation('translations'),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ValidateEmail);
