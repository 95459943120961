import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      En: {
        translations: {
          //GENERAL
          UserNotValid: 'Incorrect username or password.',
          EmailConfirmed: 'Thank you, your email has been confirmed.',
          Return: 'Back to the main',
          EmailNotConfirmed:
            'Oops! something went wrong, please check your token validation.',
          EmailConfirmation: 'Email Confirmation',
          ViewOverview: 'View',
          Default: 'Default',
          Single: 'Single',
          Maker: 'Maker',
          Model: 'Model',
          Active: 'Active',
          Inactive: 'Inactive',
          Event: 'Event',
          CarNotifications: 'Vehicle notifications',
          Name: 'Name',
          Delete: 'Delete',
          Save: 'Save',
          None: 'None',
          Car: 'Vehicle',
          Cancel: 'Cancel',
          OK: 'OK',
          Close: 'Close',
          DeleteCar: 'You are about to delete this vehicle',
          ActionInfo: 'Are you sure you want to do it?',
          Reset: 'Reset Password',
          // LOGIN
          Date: 'Date',
          StartTime: 'Start Time',
          EndTime: 'End Time',
          EmailSent: 'Email Sent',
          EnterNewPassword: 'Enter your new password',
          UsernameEmail: 'Username or Email',
          Password: 'Password',
          ForgotPassword: 'Forgot your password?',
          ClickHere: 'Click here',
          EnterEmail: 'Enter your email to reset your password',
          EmailSentTo1: 'We have sent a message to',
          EmailSentTo2: 'to reset your password',
          NotMyEmail: 'This is not my email',
          ResendEmail: 'Resend email',
          // MENU
          Dashboard: 'Dashboard',
          Login: 'Login',
          Overview: 'Overview',
          Geofence: 'Geofence',
          MyCars: 'My Vehicles',
          Notification: 'Notification',
          Units: 'Units',
          Logout: 'Logout',
          Kilometers: 'Kilometers',
          Miles: 'Miles',
          //OVERVIEW
          Brakes: 'Abrupt braking',
          CurrentLocation: 'Current Location',
          DeviceList: 'List of Vehicles',
          SelectedCar: 'Selected Vehicle',
          TripStart: 'Trip Started',
          LastTripStart: 'Last Trip Started',
          TripEnd: 'Trip Completed',
          TripOf: 'The trip of ',
          HasEnded: ' has ended at ',
          EverythingGood: 'Everything looks pretty good.',
          ProblemDetected: 'Problems have been detected.',
          LastTripDone: 'Last trip complete',
          CheckEngine: 'Check Engine Warning',
          DtcProblem: 'Your vehicle has a problem: ',
          DateAdded: 'Date Added',
          Issues: 'Issues',
          IssuesMessage: 'Everything looks pretty good',
          BatteryLife: 'Battery Life',
          License: 'License',
          Odometer: 'Odometer',
          Abrupt: 'Abrupt Braking',
          Idling: 'Idling',
          RapidAccels: 'Rapid Accels',
          Distance: 'Distance',
          Duration: 'Duration',
          MaxSpeed: 'Max Speed',
          AVGSpeed: 'AVG Speed',
          EngineSpeed: 'Engine Speed',
          Efficiency: 'Efficiency',
          TripHistory: 'Trip History',
          ProblemInfo:
            'Clebber allows you to review active DTC to help you plan when you should visit a mechanic.',
          BatteryInfo:
            'Your vehicle battery may sometimes be below the GOOD range, which is normal. If this happens regularly, you should visit your mechanic.',
          Good: 'Everything looks pretty good.',
          Check: 'Check',
          Low: 'Very Low',
          Great: 'Great',
          Bad: 'Bad',
          View: 'View',
          //GEOFENCE
          NewGeofence: 'New Geofence',
          Activated: 'Activated',
          Deactivated: 'Deactivated',
          CarName: 'Vehicle Name',
          MyGeofence: 'My Geofence',
          GeofenceName: 'Geofence Name',
          Address: 'Address',
          Radius: 'Radius',
          SetRadius: 'Set Radius',
          Alert: 'Alert',
          Details: 'Details',
          AllowAlert: 'Allow Alert',
          DotAddress: 'Address to search',
          //MYCARS
          EditCar: 'Edit vehicle',
          //DIALOGS
          EmptyOld: '"Old Password" field is required.',
          EmptyNew: '"New Password" field is required.',
          EmptyRepeat: '"Repeat Password" field is required.',
          ShortPassword:
            'Your new password is to short (must be at least 6 characters).',
          NotEquals: 'Your new password must match.',
          OldPassword: 'Old Password',
          NewPassword: 'New Password',
          RepeatPassword: 'Repeat Password',
          Language: 'Language',
          ChangePassword: 'Change Password',
          ImperialMetric: 'Imperial System',
          ISOMetric: 'Metric System',
          Spanish: 'Español',
          English: 'English',
          Savetitle: 'Delete Geofence',
          Savesubtitle: 'Do you want to delete?',
          Errortitle: 'There has been an error',
          Errorsubtitle: 'Please fill correctly every field in the form',
          All: 'All',
          Connected: 'Connected',
          //Profile
          Profile: 'Profile',
          Email: 'Email',
          CellPhone: 'Cell Phone',
          MeasurementSystem: 'Measurement System'
        }
      },
      Es: {
        translations: {
          //GENERAL
          UserNotValid: 'Usuario o Contraseña no son validos.',
          EmailConfirmed: 'Gracias, su correo ha sido confirmado.',
          Return: 'Regresar al inicio',
          EmailNotConfirmed:
            'Vaya! algo esta mal, porfavor revise su token de validacion.',
          EmailConfirmation: 'Confirmacion de correo',
          ViewOverview: 'Vista',
          Default: 'Por defecto',
          Single: 'Individual',
          Maker: 'Marca',
          Model: 'Modelo',
          Active: 'Activo',
          Inactive: 'Inactivo',
          Event: 'Evento',
          CarNotifications: 'Notificaciones del vehiculo',
          Name: 'Nombre',
          Delete: 'Eliminar',
          Save: 'Guardar',
          None: 'Ninguno',
          Car: 'Vehiculo',
          Cancel: 'Cancelar',
          OK: 'Aceptar',
          Close: 'Cerrar',
          DeleteCar: 'Estás a punto de borrar este vehiculo',
          ActionInfo: '¿Estás seguro que deseas hacerlo?',
          Reset: 'Restablecer Contraseña',
          EditCar: 'Editar vehiculo',
          // LOGIN
          Date: 'Fecha',
          StartTime: 'Tiempo inicial',
          EndTime: 'Tiempo final',
          EmailSent: 'Correo enviado',
          EnterNewPassword: 'Ingresa tu nueva contraseña',
          UsernameEmail: 'Usuario o correo electonico',
          Password: 'Contraseña',
          ForgotPassword: '¿Olvidaste tu contraseña?',
          ClickHere: 'Haz click aqui',
          EnterEmail:
            'Ingresa tu correo electronico para restablecer tu contraseña',
          EmailSentTo1: 'Te hemos mandado un mensaje a',
          EmailSentTo2: 'para que reestablezcas tu contraseña',
          NotMyEmail: 'Este no es mi correo',
          ResendEmail: 'Volver a enviar correo',
          // MENU
          Dashboard: 'Dashboard',
          Login: 'Iniciar Sesión',
          Overview: 'Vista General',
          Geofence: 'Geocerca',
          MyCars: 'Mis Vehiculos',
          Notification: 'Notificaciones',
          Units: 'Unidad de Medida',
          Logout: 'Cerrar sesión',
          Kilometers: 'Kilometros',
          Miles: 'Millas',
          //OVERVIEW
          Brakes: 'Aceleraciones',
          CurrentLocation: 'Ubicacion Actual',
          DeviceList: 'Lista de Vehiculos',
          SelectedCar: 'Vehiculo Seleccionado',
          TripStart: 'Viaje iniciado',
          LastTripStart: 'Ultimo Viaje Iniciado',
          TripEnd: 'Viaje terminado',
          TripOf: 'El viaje de ',
          HasEnded: ' ha finalizado en ',
          EverythingGood: 'Todo se ve bastante bien.',
          ProblemDetected: 'Se han detectado problemas.',
          LastTripDone: 'El último viaje realizado',
          CheckEngine: 'Indicador encendido, revisar motor',
          DtcProblem: 'Tu vehiculo presenta un problema: ',
          DateAdded: 'Agregado el',
          Issues: 'Problemas',
          IssuesMessage: 'Todo está perfecto',
          BatteryLife: 'Estado de Batería',
          License: 'Matricula',
          Odometer: 'Odómetro',
          Abrupt: 'Frenado abrupto',
          Idling: 'Inactivo',
          RapidAccels: 'Aceleración rápida',
          Distance: 'Distancia',
          Duration: 'Duración',
          MaxSpeed: 'Velocidad Max',
          AVGSpeed: 'Velocidad Promedio',
          EngineSpeed: 'Velocidad Motor',
          Efficiency: 'Eficiencia',
          TripHistory: 'Historial de viaje',
          ProblemInfo:
            'Clebber te permite revisar los código de detección de problemas activos para ayudarte a planear cuándo deberías visitar un mecánico.',
          BatteryInfo:
            'Tu batería puede que en ocasiones esté por debajo del rango BUENO, lo que es normal. Si esto sucede con regularidad, debes de visitar a tu mecánico.',
          Good: 'Todo está perfecto.',
          Check: 'Revisar',
          Low: 'Muy Bajo',
          Great: 'Excelente',
          Bad: 'Malo',
          View: 'Ver',
          //GEOFENCE
          NewGeofence: 'Nueva  Geocerca',
          Activated: 'Activado',
          Deactivated: 'Desactivado',
          CarName: 'Nombre del vehiculo',
          MyGeofence: 'Mis Geocercas',
          GeofenceName: 'Nombre de la Geocerca',
          Address: 'Direccion',
          Radius: 'Radio',
          SetRadius: 'Establecer Radio',
          Alert: 'Alerta',
          Details: 'Detalles',
          AllowAlert: 'Permitir Alertas',
          DotAddress: 'Direccion del Punto',
          //DIALOGS
          EmptyOld: 'El campo "Contraseña Antigua" es obligatorio.',
          EmptyNew: '"Nueva Contraseña" es obligatorio.',
          EmptyRepeat: 'El campo "Repetir Contraseña" es obligatorio.',
          ShortPassword:
            'Tu nueva contraseña es muy corta (debe tener al menos 6 caracteres).',
          NotEquals: 'Tu nueva contraseña debe coincidir.',
          OldPassword: 'Contraseña Antigua',
          NewPassword: 'Nueva Contraseña',
          RepeatPassword: 'Repetir Contraseña',
          Language: 'Idioma',
          ChangePassword: 'Cambiar Contraseña',
          ImperialMetric: 'Sistema Imperial',
          ISOMetric: 'Sistema Métrico',
          Spanish: 'Español',
          English: 'English',
          Savetitle: 'Borrar Geocerca',
          Savesubtitle: '¿Deseas borrar la geocerca?',
          Errortitle: 'Ha ocurrido un error',
          Errorsubtitle: 'Por favor,llene correctamente todos los campos',
          All: 'Todos',
          Connected: 'Conectados',
          //Profile
          Profile: 'Perfil',
          Email: 'Correo Electronico',
          CellPhone: 'Telefono celular',
          MeasurementSystem: 'Sistema de medida'
        }
      }
    },
    fallbackLng: 'En',
    debug: false,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ','
    },

    react: {
      wait: true,
      useSuspense: false
    }
  });

export default i18n;
