/* eslint-disable no-console */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import imageCover from 'assets/img/profile_background.png';
import logo from 'assets/img/img_logo2.png';
import image from 'assets/img/profile_background.png';
import dashboardStyle from 'assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx';
import Footer from 'components/Footer/Footer.jsx';
import Header from 'components/Header/Header.jsx';
import Sidebar from 'components/Sidebar/Sidebar.jsx';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import dashboardRoutes from 'routes/dashboard.jsx';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
  changePassword,
  onSettings,
  setCurrentCar,
  setLenguage,
  setUnits
} from './../../actions/dashboard';
import * as R from 'ramda';
const MySwal = withReactContent(Swal);

const switchRoutes = (
  <Switch>
    {dashboardRoutes.map((prop, key) => {
      if (prop.redirect)
        return <Redirect from={prop.path} to={prop.to} key={key} />;
      return <Route path={prop.path} component={prop.component} key={key} />;
    })}
  </Switch>
);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      menu: {
        title: '',
        options: []
      },
      menuType: props.menuType,
      index: undefined,
      loading: false,
      oldPassword: '',
      newPassword: '',
      repeatPassword: '',
      error: null,
      submitted: false
    };
    this.mainPanel = React.createRef();
    this.resizeFunction = this.resizeFunction.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  isRouteTermsPrivacy() {
    return (
      this.props.location.pathname === '/terms.html' ||
      this.props.location.pathname.includes('policy')
    );
  }

  withFooter() {
    return (
      this.props.location.pathname !== '/dashboard' &&
      this.props.location.pathname !== '/overview'
    );
  }

  getRoute() {
    return this.props.location.pathname !== '/dashboard';
  }

  isOverview() {
    return this.props.location.pathname === '/overview';
  }

  isProfile() {
    return this.props.location.pathname === '/profile';
  }

  isOnlyLogin() {
    return this.props.location.pathname === '/login';
  }

  isRouteLogin() {
    return (
      this.props.location.pathname === '/login' ||
      this.props.location.pathname.includes('/forgot') ||
      this.props.location.pathname.includes('validate')
    );
  }

  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }

  static getDerivedStateFromProps(props) {
    const { settingOpen, menuType, t, menuIndex } = props;
    if (settingOpen) {
      switch (menuType) {
        case 'Language': {
          const options = [t('English'), t('Spanish')];
          return {
            menu: {
              title: t('Language'),
              options
            },
            index: menuIndex
          };
        }
        case 'Units': {
          const options = [t('Kilometers'), t('Miles')];
          return {
            menu: {
              title: t('Units'),
              options
            },
            index: menuIndex
          };
        }
        default: {
          return {
            menu: {
              title: t('ChangePassword')
            },
            index: menuIndex
          };
        }
      }
    }
    return null;
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeFunction);
    if (
      this.props.loggedIn === false &&
      this.isRouteLogin() === false &&
      this.isRouteTermsPrivacy() === false
    ) {
      this.props.history.push('/login');
    }
  }

  componentDidUpdate(e) {
    const { error, status } = this.props;
    if (e.history.location.pathname !== e.location.pathname) {
      // if (this.mainPanel) this.mainPanel.current.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
    if (status && this.state.submitted) {
      this.setState({ submitted: false });
      if (status === 200) {
        this.setState({
          error: null,
          oldPassword: '',
          newPassword: '',
          repeatPassword: ''
        });
        this.handleClose();
        let timerInterval;
        MySwal.fire({
          title: 'Success!',
          text: 'Your password was changed succesfully!',
          type: 'success',
          timer: 2000,
          showConfirmButton: false,
          onBeforeOpen: () => {
            //Swal.showLoading()
            timerInterval = setInterval(() => {}, 100);
          },
          onClose: () => {
            clearInterval(timerInterval);
          }
        }).then(result => {
          if (
            // Read more about handling dismissals
            result.dismiss === Swal.DismissReason.timer
          ) {
          }
        });
      } else if (error && this.state.submitted && status !== 200) {
        this.setState({ submitted: false, error });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeFunction);
  }

  handleClose = () => {
    const { menu } = this.props;
    this.props.onSettings({ open: false, type: menu });
  };

  handleChange = (event, value) => {
    const { menu } = this.state;
    if (menu.title === 'Language' || menu.title === 'Idioma') {
      this.props.setLenguage(value);
    } else {
      this.props.setUnits(value);
      this.setState({ index: value });
    }
  };

  handleChangeText = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleSubmit = function(event) {
    event.preventDefault();
    const { oldPassword, newPassword, repeatPassword } = this.state;
    const error = this.validateForm(oldPassword, newPassword, repeatPassword);
    if (error === null) {
      this.setState({ submitted: true });
      this.props.changePassword({ oldPassword, newPassword, repeatPassword });
    } else {
      this.setState({ error });
    }
  };

  validateForm = (oldP, newP, repeatP) => {
    const { t } = this.props;
    if (oldP === null || oldP === undefined || oldP === '') {
      return t('EmptyOld');
    }

    if (newP === null || newP === undefined || newP === '') {
      return t('EmptyNew');
    }

    if (repeatP === null || repeatP === undefined || repeatP === '') {
      return t('EmptyRepeat');
    }

    if (newP.length < 6) {
      return t('ShortPassword');
    }

    if (newP !== repeatP) {
      return t('NotEquals');
    }
    return null;
  };

  render() {
    const {
      classes,
      menuIndex,
      location,
      settingOpen,
      t,
      ...rest
    } = this.props;
    const bg =
      imageCover !== undefined &&
      !location.pathname.includes('forgot') &&
      !location.pathname.includes('validate');
    let backGround = {
      position: 'relative',
      height: '100vh',
      background: 'url(' + imageCover + ')',
      backgroundSize: 'cover'
    };

    if (bg) {
      backGround = {
        position: 'relative',
        height: '100vh',
        background: 'url(' + imageCover + ')',
        backgroundSize: 'cover'
      };
    }
    const {
      menu,
      index,
      oldPassword,
      newPassword,
      repeatPassword,
      error
    } = this.state;
    let currentIndex = index;
    if (!R.pathOr('', ['options'])(menu).includes(currentIndex)) {
      if (menu.title.toLowerCase().includes('uni')) {
        if (
          localStorage.getItem('units') !== null &&
          localStorage
            .getItem('units')
            .toLowerCase()
            .includes('kil')
        ) {
          currentIndex = t('Kilometers');
        } else {
          currentIndex = t('Miles');
        }
      } else {
        if (
          localStorage.getItem('language') !== null &&
          localStorage.getItem('language') === 'Español'
        ) {
          currentIndex = t('Spanish');
        } else {
          currentIndex = t('English');
        }
      }
    }

    if (this.isRouteTermsPrivacy()) {
      return (
        <div className={classes.wrapperLogin} style={backGround}>
          <div className={classes.container}>{switchRoutes}</div>
        </div>
      );
    }

    if (this.isRouteLogin())
      return (
        <div style={backGround}>
          {(this.isOnlyLogin() && (
            <div
              style={{
                position: 'relative',
                height: '100vh',
                width: '100vw',
                backgroundSize: 'cover'
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  background: 'linear-gradient(0deg, #B7F1FF, #29B7DA)',
                  height: '100vh',
                  width: '100vw',
                  opacity: 0.5,
                  backgroundSize: 'cover'
                }}
              />
              <div className={classes.contentLogin}>
                <div className={classes.container}>{switchRoutes}</div>
              </div>
            </div>
          )) || (
            <div className={classes.contentLogin}>
              <div className={classes.container}>{switchRoutes}</div>
            </div>
          )}
        </div>
      );
    return (
      <div className={classes.wrapper}>
        <Dialog
          maxWidth='lg'
          open={settingOpen}
          onClose={this.handleClose}
          keepMounted
          aria-labelledby='confirmation-dialog-title'
          {...rest}
        >
          <DialogTitle id='confirmation-dialog-title'>{menu.title}</DialogTitle>
          <DialogContent>
            {(menu.options && (
              <RadioGroup
                ref={ref => {
                  this.radioGroupRef = ref;
                }}
                value={currentIndex || menuIndex}
                onChange={this.handleChange}
              >
                {menu.options.map(option => (
                  <FormControlLabel
                    value={option}
                    key={option}
                    control={<Radio style={{ color: '#29B7DA' }} />}
                    label={option}
                  />
                ))}
              </RadioGroup>
            )) || (
              <form
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  width: 400
                }}
                onSubmit={this.handleSubmit}
              >
                <TextField
                  label={t('OldPassword')}
                  className={classes.textField}
                  value={oldPassword}
                  type='password'
                  onChange={this.handleChangeText('oldPassword')}
                  margin='normal'
                  name='oldPassword'
                  variant='outlined'
                  style={{ width: '90%' }}
                />
                <TextField
                  label={t('NewPassword')}
                  className={classes.textField}
                  value={newPassword}
                  type='password'
                  name='newPassword'
                  onChange={this.handleChangeText('newPassword')}
                  margin='normal'
                  variant='outlined'
                  style={{ width: '90%' }}
                />
                <TextField
                  label={t('RepeatPassword')}
                  className={classes.textField}
                  value={repeatPassword}
                  type='password'
                  name='repeatPassword'
                  onChange={this.handleChangeText('repeatPassword')}
                  margin='normal'
                  variant='outlined'
                  style={{ width: '90%' }}
                />
                {error && (
                  <h6
                    style={{
                      transform: 'none',
                      textTransform: 'none',
                      margin: 0,
                      color: 'red',
                      textAlign: 'center'
                    }}
                  >
                    {error}
                  </h6>
                )}
                <Button
                  type='submit'
                  style={{
                    width: '80%',
                    backgroundColor: '#29B7DA',
                    color: '#FFFF'
                  }}
                >
                  {t('Save')}
                </Button>
              </form>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color='primary' autoFocus>
              {t('Close')}
            </Button>
          </DialogActions>
        </Dialog>
        <Sidebar
          routes={dashboardRoutes}
          logo={logo}
          image={image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color='blue'
          location={location}
          {...rest}
        />
        <div className={classes.mainPanel} ref={this.mainPanel}>
          <Header
            routes={dashboardRoutes}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {this.getRoute() ? (
            this.isOverview() || this.isProfile() ? (
              <div className={classes.content} style={{ padding: 0 }}>
                <div
                  className={classes.container}
                  style={{ padding: 0, margin: 0 }}
                >
                  {switchRoutes}
                </div>
              </div>
            ) : (
              <div className={classes.content}>
                <div className={classes.container}>{switchRoutes}</div>
              </div>
            )
          ) : (
            <div className={classes.map}>{switchRoutes}</div>
          )}
          {this.withFooter() ? <Footer /> : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    settingOpen: R.pathOr(false, ['dashboard', 'settingOpen'])(state),
    menu: R.pathOr({}, ['dashboard', 'menu'])(state),
    menuType: R.pathOr('', ['dashboard', 'menuType'])(state),
    menuIndex: R.pathOr('', ['dashboard', 'menuindex'])(state),
    loggedIn: R.pathOr(false, ['auth', 'loggedIn'])(state),
    currentCar: R.pathOr({}, ['dashboard', 'currentCar'])(state),
    error: R.pathOr('', ['dashboard', 'error'])(state),
    status: R.pathOr(0, ['dashboard', 'status'])(state)
  };
}

const mapDispatchToProps = {
  onSettings: onSettings,
  setLenguage: setLenguage,
  setUnits: setUnits,
  setCurrentCar: setCurrentCar,
  changePassword: changePassword
};

App.propTypes = {
  classes: PropTypes.object.isRequired,
  onSettings: PropTypes.func,
  t: PropTypes.func,
  setLenguage: PropTypes.func,
  setUnits: PropTypes.func,
  setCurrentCar: PropTypes.func,
  currentCar: PropTypes.object,
  changePassword: PropTypes.func,
  error: PropTypes.string,
  status: PropTypes.number,
  location: PropTypes.object,
  menuIndex: PropTypes.string,
  settingOpen: PropTypes.bool,
  menu: PropTypes.object,
  menuType: PropTypes.string,
  loggedIn: PropTypes.bool,
  history: PropTypes.object
};

export default compose(
  withStyles(dashboardStyle),
  withRouter,
  withTranslation('translations'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(App);
